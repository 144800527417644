import React from 'react';

import S from './styles';

const Options = ({ onChange, text, checked = false }) => (
  <S.Wrapper>
    <S.Wrapper.Inner>
      <S.Checkbox onChange={onChange} checked={checked}>
        {text}
      </S.Checkbox>
    </S.Wrapper.Inner>
  </S.Wrapper>
);

export default Options;
