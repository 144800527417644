import React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';

import hotReload from 'configuration/hot-reload';

import App from './App';

const Root = ({ store, graphql }) => (
  <ApolloProvider client={graphql}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>
);

export default hotReload(Root);
