// @flow
import React from 'react';
import { Editor as DraftEditor } from 'draft-js';

import { customStyleMap, customStyleFn, blockStyleFn } from 'modules/common/utils';

import 'draft-js/dist/Draft.css';

import type { EditorProps } from './types';

const Editor = ({ element, editorState, setEditorState, onFocus }: EditorProps) => (
  <DraftEditor
    id={element.id}
    onFocus={onFocus}
    onChange={setEditorState}
    editorState={editorState}
    customStyleMap={customStyleMap}
    customStyleFn={customStyleFn}
    blockStyleFn={blockStyleFn}
    placeholder={element.placeholder}
  />
);

export default React.memo<EditorProps>(Editor);
