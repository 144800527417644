import React, { useEffect, useState } from 'react';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { debounce, get } from 'lodash-es';

import { getOptions } from 'modules/common/utils';

import Editor from './Editor';
import Toolbar from './Toolbar';

import S from './styles';

export const DEBOUNCE_EDITOR_CHANGE = 1000;

const DraftJS = ({ element, disabled, onChange }) => {
  const [editor, setEditor] = useState(EditorState.createEmpty());

  useEffect(() => {
    let rawValue = get(element, 'rawValue');
    let defaultValue = get(element, 'defaultValue');

    if (typeof rawValue === 'string' && rawValue) {
      rawValue = JSON.stringify(rawValue);
    }

    // for some reason, this is showing the text content as "null" without this
    if (defaultValue === null) {
      defaultValue = '';
    }

    const content = rawValue ? convertFromRaw(rawValue) : stateFromHTML(defaultValue);
    const state = EditorState.createWithContent(content);

    setEditor(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateChange = debounce(() => {
    const content = editor.getCurrentContent();
    const options = getOptions(editor);

    onChange({
      ...element,
      defaultValue: stateToHTML(content, options),
      rawValue: JSON.stringify(convertToRaw(content)),
    });
  }, DEBOUNCE_EDITOR_CHANGE);

  const handleChange = (state) => {
    setEditor(state);
    updateChange();
  };

  return (
    <S.Editor>
      <Toolbar editor={editor} onChange={handleChange} />
      <Editor
        spellCheck
        element={element}
        editor={editor}
        onChange={handleChange}
        readOnly={disabled}
      />
    </S.Editor>
  );
};

export default DraftJS;
