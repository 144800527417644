// @flow
import React, { useState } from 'react';
import { noop } from 'lodash-es';

import { Modal } from 'modules/common/components';

import type { ImportModalProps } from './types';
import ModalContent from './ModalContent';

const ImportModal = ({
  // DATA
  isOpen,

  // CALLBACKS
  onImport = noop,
  onCancel = noop,
}: ImportModalProps) => {
  const [elements, setElements] = useState('');
  const [template, setTemplate] = useState('');

  const handleImport = () => onImport(elements, template);

  return (
    <Modal
      isOpen={isOpen}
      title="Import"
      width="700px"
      onConfirm={handleImport}
      onCancel={onCancel}
      okText="Import"
    >
      <ModalContent
        elements={elements}
        template={template}
        onChangeElements={setElements}
        onChangeTemplate={setTemplate}
      />
    </Modal>
  );
};

export default ImportModal;
