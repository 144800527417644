import styled from '@emotion/styled';

const Editor = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;

  & > div {
    padding: 12px;

    &:first-of-type {
      padding: 8px 4px;
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

const Toolbar = styled.div`
  text-align: center;
  background: #f5f5f5;
  border-bottom: 1px solid #d9d9d9;
  padding: 15px 0;

  .normal {
    color: #2e3f50;
    background: no-repeat;
    border: 1px solid transparent;
    border-radius: 3px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin: 0 3px;
    padding: 3px 5px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    display: inline-block;

    &:hover {
      background-color: #f0f5fa;
    }

    &.active {
      background-color: #fff;
      border-color: #ced4f1;
    }
  }
`;

export default {
  Editor,
  Toolbar,
};
