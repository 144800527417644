import React from 'react';
import { noop } from 'lodash-es';

import { Toggle } from '../../fields';

const ElementToggle = ({ element, disabled, onChange = noop }) => {
  switch (element.id) {
    case 'bookTitleFrontcover':
    case 'bookTitle':
    case 'authorName':
    case 'bookDescription':
    case 'authorBio':
    case 'authorImageFront':
    case 'authorImageBack':
    case 'brokerInfo':
    case 'brokerLogo':
    case 'brokerContact':
    case 'housingLogo': {
      return <Toggle checked={!disabled} onChange={onChange} />;
    }

    case 'spineName':
    case 'spineAuthor':
    case 'price': {
      return <Toggle checked disabled />;
    }

    default: {
      return null;
    }
  }
};

export default ElementToggle;
