/* eslint-disable global-require */
import React from 'react';
import { isDevelopmentEnvironment } from 'modules/core/settings';

if (isDevelopmentEnvironment()) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');

  whyDidYouRender(React, {
    trackExtraHooks: [[require('react-redux/lib'), 'useSelector']],
  });
}
