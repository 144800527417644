import React from 'react';
import { noop } from 'lodash-es';

import { DefaultValueInput, Position } from '../controls';
import S from './styles';

const UserImage = ({ element, onChange = noop }) => (
  <S.Wrapper>
    <DefaultValueInput element={element} onChange={onChange} />
    <Position element={element} onChange={onChange} />
  </S.Wrapper>
);

export default UserImage;
