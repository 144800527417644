// @flow
import React, { Children } from 'react';
import { Avatar } from 'antd';

import { ChevronIcon, DefaultAvatar } from 'web/components/SvgIcon';
import { Item } from 'modules/nav/components/NavBar/components/Menu/components';
import { Version } from './components';

import type { UserMenuProps } from './types';
import { getVersion } from './utils';
import S from './styles';

const UserMenu = ({ username, email, avatar, children }: UserMenuProps) => {
  const version = getVersion();
  const dropdownAvatar = avatar ? <Avatar src={avatar} /> : <DefaultAvatar />;

  const dropdownList = (
    <S.Menu>
      <S.Menu.Item>
        <S.ProfileLink>
          <S.DropdownAvatar>{dropdownAvatar}</S.DropdownAvatar>
          <S.Details>
            <S.Details.Name>{username}</S.Details.Name>
            <S.Details.Email title={email}>{email}</S.Details.Email>
          </S.Details>
        </S.ProfileLink>
      </S.Menu.Item>
      {Children.map(children, (child) => (
        <S.Menu.Item>{child}</S.Menu.Item>
      ))}
      {version && (
        <S.Menu.Item disabled>
          <Version version={version} />
        </S.Menu.Item>
      )}
    </S.Menu>
  );

  return (
    <S.Dropdown overlay={dropdownList} trigger={['click']}>
      <S.Button>
        <S.Button.Label>
          <S.AvatarWrapper>{dropdownAvatar}</S.AvatarWrapper>
          <S.UserName>{username}</S.UserName>
        </S.Button.Label>
        <S.Button.Icon>
          <ChevronIcon />
        </S.Button.Icon>
      </S.Button>
    </S.Dropdown>
  );
};

UserMenu.Item = Item;

export default UserMenu;
