import React from 'react';
import { Tabs } from 'antd';
import { noop } from 'lodash-es';

import { Scrollbars } from 'modules/common/components';

import Elements from '../Elements';
import Template from '../Template';
import ToggleGuides from '../ToggleGuides';

const { TabPane } = Tabs;

const SCROLLBARS_HEIGHT = 'calc(100vh - 140px)';

const SideBar = ({
  elements,
  template,
  showGuides,
  onToggleGuides = noop,
  onElementsChange = noop,
  onTemplateChange = noop,
  onTemplateReset = noop,
}) => {
  const extra = <ToggleGuides showGuides={showGuides} onToggle={onToggleGuides} />;

  return (
    <Tabs defaultActiveKey="1" onChange={noop} centered tabBarExtraContent={extra}>
      <TabPane tab="Elements" key="1" style={{ padding: '0 12px' }}>
        <Scrollbars style={{ height: SCROLLBARS_HEIGHT }}>
          <Elements elements={elements} onChange={onElementsChange} />
        </Scrollbars>
      </TabPane>
      <TabPane tab="Template" key="2">
        <Template template={template} onReset={onTemplateReset} onChange={onTemplateChange} />
      </TabPane>
    </Tabs>
  );
};

export default SideBar;
