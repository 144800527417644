import styled from 'styled-components';

const Wrapper = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding: 12px;
  padding-bottom: 0;
  box-sizing: border-box;

  &:last-child {
    border-bottom: none;
  }

  & .ant-input-number {
    width: 100% !important;
  }
`;

export default {
  Wrapper,
};
