import React from 'react';
import { noop } from 'lodash-es';

import { DefaultValueFormatted, Font, Position, Settings } from '../controls';
import S from './styles';

const RichText = ({ element, onChange = noop }) => (
  <S.Wrapper>
    <DefaultValueFormatted element={element} onChange={onChange} />
    <Font element={element} onChange={onChange} />
    <Position element={element} onChange={onChange} />
    <Settings element={element} onChange={onChange} />
  </S.Wrapper>
);

export default RichText;
