// @flow
import type { Options } from './types';

export const options: Options = {
  inlineStyles: [
    {
      type: 'bold',
      style: 'BOLD',
      icon: 'bold',
    },
    {
      type: 'italic',
      style: 'ITALIC',
      icon: 'italic',
    },
    {
      type: 'underline',
      style: 'UNDERLINE',
      icon: 'underline',
    },
  ],
  listStyles: [
    {
      type: 'unordered-list-item',
      style: 'unordered-list-item',
      icon: 'unordered-list',
    },
    {
      type: 'ordered-list-item',
      style: 'ordered-list-item',
      icon: 'ordered-list',
    },
  ],
  blockStyles: [
    {
      type: 'left-align',
      style: 'left-align',
      icon: 'align-left',
    },
    {
      type: 'center-align',
      style: 'center-align',
      icon: 'align-center',
    },
    {
      type: 'right-align',
      style: 'right-align',
      icon: 'align-right',
    },

    {
      type: 'justify',
      style: 'justify',
      icon: 'menu',
    },
  ],
  highlight: {
    type: 'highlight',
    style: 'HIGHLIGHT',
    icon: 'highlight',
  },
  fontSize: {
    type: 'font-size',
    icon: 'font-size',
    values: [
      '10',
      '10.5',
      '11',
      '11.5',
      '12',
      '12.5',
      '13',
      '13.5',
      '14',
      '14.5',
      '15',
      '15.5',
      '16',
      '16.5',
      '17',
      '17.5',
      '18',
      '18.5',
      '19',
      '19.5',
      '20',
      '20.5',
      '21',
      '21.5',
      '22',
      '22.5',
      '23',
      '23.5',
      '24',
    ],
  },
  fontFamily: {
    type: 'font-family',
    icon: 'edit',
    values: ['Caladea', 'Minion Pro'],
  },
};
