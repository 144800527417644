import React from 'react';
import { Row, Col, Form } from 'antd';
import { get, noop } from 'lodash-es';

import { ColorPicker, Number, Select } from '../fields';
import S from './styles';

const fonts = [
  { title: 'Caladea', value: 'Caladea' },
  { title: 'Minion Pro', value: 'Minion Pro' },
  { title: 'Oswald', value: 'Oswald' },
  { title: 'Roboto', value: 'Roboto' },
  { title: 'Roboto Condensed', value: 'Roboto Condensed' },
  { title: 'Frank Ruhl Libre', value: 'Frank Ruhl Libre' },
  { title: 'Heebo', value: 'Heebo' },
  { title: 'Lato', value: 'Lato' },
  { title: 'Rubik', value: 'Rubik' },
];

const weights = [
  { title: 'Normal', value: 'normal' },
  { title: 'Bold', value: 'bold' },
  { title: 'Bolder', value: 'bolder' },
  { title: 'Lighter', value: 'lighter' },
  { title: '100', value: '100' },
  { title: '200', value: '200' },
  { title: '300', value: '300' },
  { title: '400', value: '400' },
  { title: '500', value: '500' },
  { title: '600', value: '600' },
  { title: '700', value: '700' },
  { title: '800', value: '800' },
  { title: '900', value: '900' },
];

const aligns = [
  { title: 'Center', value: 'center' },
  { title: 'Left', value: 'left' },
  { title: 'Right', value: 'right' },
  { title: 'Justify', value: 'justify' },
];

const Font = ({ element, disabled, onChange = noop }) => {
  const color = get(element, 'color', '#000000');
  const fontFamily = get(element, 'fontFamily', 'Oswald');
  const fontSize = get(element, 'fontSize', '50');
  const fontWeight = get(element, 'fontWeight', 'normal');
  const textAlign = get(element, 'textAlign', 'left');
  const lineHeight = get(element, 'lineHeight', fontSize);
  const letterSpacing = get(element, 'letterSpacing', '0');
  const wordSpacing = get(element, 'wordSpacing', '0');

  const handleChange = (field) => (value) => {
    onChange({ ...element, [field]: value });
  };

  const formatter = (value) => `${value} px`;
  const parser = (value) => value.replace(' px', '');

  return (
    <S.Wrapper>
      <Form layout="vertical">
        <Row gutter={12}>
          <Col span={16}>
            <Form.Item label="Font Family">
              <Select
                disabled={disabled}
                value={fontFamily}
                values={fonts}
                onChange={handleChange('fontFamily')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Font Weight">
              <Select
                disabled={disabled}
                value={fontWeight}
                values={weights}
                onChange={handleChange('fontWeight')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item label="Font Size">
              <Number
                disabled={disabled}
                min={1}
                max={1000}
                value={fontSize}
                onChange={handleChange('fontSize')}
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Text Align">
              <Select
                disabled={disabled}
                value={textAlign}
                values={aligns}
                onChange={handleChange('textAlign')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Font Color">
              <ColorPicker disabled={disabled} value={color} onChange={handleChange('color')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item label="Line Height">
              <Number
                disabled={disabled}
                min={1}
                max={1000}
                value={lineHeight}
                onChange={handleChange('lineHeight')}
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Letter Spacing">
              <Number
                disabled={disabled}
                min={-1000}
                max={1000}
                value={letterSpacing}
                onChange={handleChange('letterSpacing')}
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Word Spacing">
              <Number
                disabled={disabled}
                min={-1000}
                max={1000}
                value={wordSpacing}
                onChange={handleChange('wordSpacing')}
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </S.Wrapper>
  );
};

export default Font;
