// @flow
import React from 'react';
import { noop } from 'lodash-es';

import { Modal } from 'modules/common/components';

import type { ExportModalProps } from './types';
import ModalContent from './ModalContent';

const ExportModal = ({
  // DATA
  isOpen,
  elements,
  template,

  // CALLBACKS
  onClose = noop,
}: ExportModalProps) => (
  <Modal isOpen={isOpen} title="Export" width="700px" footer={null} onCancel={onClose}>
    <ModalContent elements={elements} template={template} />
  </Modal>
);

export default ExportModal;
