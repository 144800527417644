import React from 'react';
import { notification, Typography } from 'antd';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import { get } from 'lodash-es';

const validation = (errors, displaySuccess = false) => {
  const messages = get(errors, ['message'], '');

  let icon;
  let message;
  let description;

  if (errors) {
    icon = <FrownOutlined />;
    message = 'That’s sad!';
    description = (
      <>
        The template has the errors: <Typography.Text type="danger">{messages}</Typography.Text>.
      </>
    );
  } else {
    icon = <SmileOutlined />;
    message = 'Congratulations!';
    description = 'No errors were found in the template.';
  }

  if (errors || displaySuccess) {
    notification.open({
      icon,
      message,
      description,
    });
  }
};

export default validation;
