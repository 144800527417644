import React from 'react';
import { Row, Col, Form } from 'antd';
import { get, noop } from 'lodash-es';

import { Number } from '../fields';
import S from './styles';

const Position = ({ element, disabled, onChange = noop }) => {
  const width = get(element, 'width');
  const height = get(element, 'height');
  const top = get(element, 'top');
  const left = get(element, 'left');

  const handleChange = (field) => (value) => {
    onChange({ ...element, [field]: value });
  };

  const formatter = (value) => `${value} px`;
  const parser = (value) => value.replace(' px', '');

  return (
    <S.Wrapper>
      <Form layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Width">
              <Number
                disabled={disabled}
                precision={3}
                value={width}
                onChange={handleChange('width')}
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Height">
              <Number
                disabled={disabled}
                precision={3}
                value={height}
                onChange={handleChange('height')}
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Top">
              <Number
                disabled={disabled}
                precision={3}
                value={top}
                onChange={handleChange('top')}
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Left">
              <Number
                disabled={disabled}
                precision={3}
                value={left}
                onChange={handleChange('left')}
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </S.Wrapper>
  );
};

export default Position;
