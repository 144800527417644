// @flow
import handlebars from "handlebars";
import { reduce } from "lodash-es";

import type { Element } from "modules/common/types";

export const mapImages = (elements: Array<Element>) => {
  const reducer = (obj: Object, { id, defaultValue }: Element): Object => {
    switch (id) {
      case "authorImageFront":
      case "authorImageBack":
        return { ...obj, userImageUrl: defaultValue };

      case "housingLogo":
        return { ...obj, fairHousingLogo: defaultValue };

      case "brokerLogo":
        return { ...obj, brokerLogo: defaultValue };

      default:
        return obj;
    }
  };

  return reduce(elements, reducer, {});
};

const compileTemplate = (
  template: string,
  elements: Array<Element>
): string => {
  let compiled = "";

  try {
    const compiler = handlebars.compile(template);
    const variables = mapImages(elements);

    compiled = compiler(variables);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return compiled;
};

export { compileTemplate };
