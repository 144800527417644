import React from 'react';
import BaseColorPicker from 'rc-color-picker';
import { noop } from 'lodash-es';

import S from './styles';
import 'rc-color-picker/assets/index.css';

const ColorPicker = ({ value, disabled, onChange = noop }) => {
  const handleChangePicker = ({ color }) => {
    if (!disabled) {
      onChange(color);
    }
  };

  return (
    <BaseColorPicker
      placement="bottomLeft"
      defaultColor="#d9d9d9"
      enableAlpha={false}
      color={value}
      onChange={handleChangePicker}
      mode="RGB"
    >
      <S.ColorPicker value={value} text={value} color={value} readOnly />
    </BaseColorPicker>
  );
};

export default ColorPicker;
