import React from 'react';

import { Inline, List, Block } from 'modules/coverEditor/components/EditorToolbar/components';

import S from './styles';

const Toolbar = (props) => (
  <S.Toolbar>
    <Inline {...props} />
    <List {...props} />
    <Block {...props} />
  </S.Toolbar>
);

export default Toolbar;
