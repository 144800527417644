import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: inline-block;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  margin: 20px;
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  height: 2475px;
  width: 3325px;
  top: 0;
  left: 0;
  z-index: 2;
  transform-origin: 0 0 0;
  border: 2px solid rgb(205, 205, 205);
  background-color: #f5f9fd;
`;

export default { Wrapper, Container };
