import React from 'react';
import { Checkbox as BaseCheckbox } from 'antd';
import { noop } from 'lodash-es';

const Checkbox = ({ label, checked, disabled, onChange = noop }) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <BaseCheckbox checked={checked} disabled={disabled} onChange={handleChange}>
      {label}
    </BaseCheckbox>
  );
};

export default Checkbox;
