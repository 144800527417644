import styled from '@emotion/styled';
import { Button, Dropdown as BaseDropdown } from 'antd';

import * as color from 'web/theme/color';
import * as font from 'web/theme/font';

const Overlay = styled.div`
  background: white;
  display: flex;
  position: relative;
  top: 0;
  box-shadow: 0px 1px 4px #b7b7b7;
`;

Overlay.Item = styled.div`
  height: 197px;
  width: 274px;
  padding: 15px 20px;
  border-right: 1px solid #f2f2f2;

  &:last-child {
    border: none;
  }
`;

Overlay.Title = styled.strong`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  display: block;
  color: black;
`;

Overlay.Text = styled.p`
  margin-bottom: 15px;
`;

Overlay.Button = styled(Button)`
  float: right;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 10px !important;
`;

const Dropdown = styled(BaseDropdown)`
  align-items: center;
  cursor: pointer;
  width: 120px;
`;

const NonLink = styled.span`
  padding: 2px 15px;
  line-height: 40px;
  display: block;
  margin: -5px -12px;
  transition: all 0.3s;
  font-size: 14px;
  font-weight: 600;
  touch-action: manipulation;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  color: #2e3f50;
`;

const Link = styled.a`
  display: flex;
  color: ${color.White};
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: ${font.MontserratFont};
  border-radius: 0;
  height: 64px;
  width: 120px;
  line-height: 64px;
  transition: background-color 0.2s ease;
  padding: 0px 20px 0 27px;

  &:hover,
  &:focus,
  &:active {
    color: ${color.White};
    background: ${color.SecondaryColor};
  }
`;

Link.Label = styled.span`
  display: inline-block;
  max-width: calc(100% - 21.99px);
  min-width: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

Link.Icon = styled.div`
  float: right;
  padding: 0 0 0 10px;
  vertical-align: middle;

  & > svg {
    display: block;
    height: 20px;
  }
`;

export default {
  Overlay,
  Dropdown,
  Link,
  NonLink,
};
