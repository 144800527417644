import styled from '@emotion/styled';
import { Modal as AntModal, Tabs as AntTabs, Button as AntButton } from 'antd';
import * as color from 'web/theme/color';
import * as font from 'web/theme/font';

const Modal = styled(AntModal)`
  .ant-modal-header {
    background: ${color.PrimaryColor};
    height: 37px;
    line-height: 37px;
    padding: 0 8px;
    border-bottom: none;

    .ant-modal-title {
      font-family: ${font.NunitoFont};
      font-weight: 800;
      letter-spacing: 0.5px;
      font-size: 16px;
      font-weight: bold;
      line-height: 37px;
      color: ${color.White};
      margin: 0;
      width: calc(100% - 25px) !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-modal-body {
    padding: 0;
    background-color: white;
  }

  .ant-modal-content {
    border-radius: 4px;
    overflow: hidden;
    background: ${color.White};


    .ant-modal-close {
      top: 0;
      right: 8px;
      display: ${({ hasCloseButton }) => (hasCloseButton ? 'block' : 'none')} !important;

      .ant-modal-close-x {
        color: ${color.White};
        text-align: right;
        line-height: 37px;
        width: 16px;
        height: 37px;
      }
    }
  }

  .ant-modal-footer {
    padding: 10px;
    text-align: right;
    background: #f6f6f6;
    border-top: 1px solid #e8e8e8;
    border-radius: 0;
  }
}
`;

const Tabs = styled(AntTabs)`
  .ant-tabs-tabpane {
    max-height: 66vh;
  }

  .ant-tabs-nav-wrap {
    background: ${color.SecondaryColor};

    .ant-tabs-tab {
      font-family: ${font.NunitoFont};
      font-size: 14px;
      font-weight 800;
      border-radius: 0 !important;
      letter-spacing: 0.5px;
      color: ${color.White};
      font-weight: 700;
      border: 0 !important;
      border-right: 1px solid ${color.PrimaryColorLight} !important;
      margin: 0 !important;
      cursor: pointer;
      background: transparent !important;

      &.ant-tabs-tab-active {
        color: ${color.GreySelectDd};
        background: ${color.White} !important;
      }
    }
  }
`;

const Button = styled(AntButton)`
  line-height: 19px;

  span {
    font-weight: 800;
  }

  &:hover,
  &:focus {
    color: inherit;
    border-color: inherit;
  }
`;

Tabs.TabPane = AntTabs.TabPane;

export default {
  Modal,
  Tabs,
  Button,
};
