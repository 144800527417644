import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

import * as color from 'web/theme/color';

const NonLink = styled.button`
  display: inline-block;
  width: 100%;
  padding: 0 !important;
  line-height: 32px !important;
  border: 1px solid ${color.GreyDivider};
  text-align: center;
  border-radius: 2px;
  background-color: ${color.White};
  font-size: 14px;
  font-weight: 600;
  touch-action: manipulation;
  outline: none;
  cursor: pointer;
  color: ${color.UserMenuText};
  vertical-align: middle;

  &:hover {
    color: ${color.UserMenuText};
    background-color: ${color.GreyMenuItemHover};
  }
`;

const Link = styled(NonLink.withComponent(RouterLink))``;

export default {
  Link,
  NonLink,
};
