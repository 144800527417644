import React from 'react';
import ReactDOM from 'react-dom';

import { Layout } from 'antd';
import { ToastContainer } from 'react-toastify';

import AppStyle from 'web/theme/appStyle';
import GlobalStyle from 'web/theme/globalTheme';

import { Router } from 'modules/router';

import 'react-toastify/dist/ReactToastify.css';

const toastContainer = ReactDOM.createPortal(
  <ToastContainer pauseOnHover={false} rtl={false} autoClose={3000} hideProgressBar closeOnClick />,
  document.getElementById('ToastContainer'),
);

const App = () => (
  <>
    <GlobalStyle />
    <AppStyle>
      <Layout>
        <Layout.Content>
          {toastContainer}
          <Router />
        </Layout.Content>
      </Layout>
    </AppStyle>
  </>
);

export default App;
