// @flow
import React, { Children } from 'react';
import { Menu as BaseMenu } from 'antd';

import { ChevronIcon } from 'web/components/SvgIcon';
import { Item } from './components';

import type { MenuProps } from './types';
import S from './styles';

const Menu = ({ title, children, to, onClick }: MenuProps) => {
  if (!children) {
    if (to) {
      return (
        <S.MenuNavLink exact to={to}>
          {title}
        </S.MenuNavLink>
      );
    }

    return <S.MenuLink onClick={onClick}>{title}</S.MenuLink>;
  }

  const dropdownList = Array.isArray(children) && (
    <BaseMenu>
      {Children.map(children, (child) => (
        <BaseMenu.Item>{child}</BaseMenu.Item>
      ))}
    </BaseMenu>
  );

  return (
    <S.Dropdown overlay={dropdownList} trigger={['click']}>
      <S.Button>
        <S.Button.Label>{title}</S.Button.Label>
        <S.Button.Icon>
          <ChevronIcon />
        </S.Button.Icon>
      </S.Button>
    </S.Dropdown>
  );
};

Menu.Item = Item;

export default Menu;
