import styled from '@emotion/styled';
import * as color from 'web/theme/color';
import * as font from 'web/theme/font';

const Notification = styled.div`
  font-size: 12px;
  font-family: ${font.MontserratFont};
`;

Notification.Title = styled.h3`
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
`;

Notification.Text = styled.p`
  color: ${color.NotificationTextColor};
`;

export default {
  Notification,
};
