export const sections = {
  FRONT_COVER: Symbol('FRONT_COVER'),
  BACK_COVER: Symbol('BACK_COVER'),
  SPINE: Symbol('SPINE'),
};

export const schemas = {
  PLAIN_TEXT: Symbol('PLAIN_TEXT'),
  RICH_TEXT: Symbol('RICH_TEXT'),
  USER_IMAGE: Symbol('USER_IMAGE'),
  MONOCHROMATIC_IMAGE: Symbol('MONOCHROMATIC_IMAGE'),
  SPINE: Symbol('SPINE'),
  CUSTOM: Symbol('CUSTOM'),
};

export const definition = {
  bookTitleFrontcover: schemas.PLAIN_TEXT,
  bookTitle: schemas.PLAIN_TEXT,
  authorName: schemas.PLAIN_TEXT,
  bookDescription: schemas.RICH_TEXT,
  authorBio: schemas.RICH_TEXT,
  brokerInfo: schemas.RICH_TEXT,
  authorImageFront: schemas.USER_IMAGE,
  authorImageBack: schemas.USER_IMAGE,
  brokerLogo: schemas.MONOCHROMATIC_IMAGE,
  housingLogo: schemas.MONOCHROMATIC_IMAGE,
  spineName: schemas.SPINE,
  spineAuthor: schemas.SPINE,
  brokerContact: schemas.CUSTOM,
  price: schemas.CUSTOM,
};

export const titles = {
  bookTitleFrontcover: 'Book Title - Front Cover',
  bookTitle: 'Book Title - Back Cover',
  authorName: 'Author Name - Front Cover',
  bookDescription: 'Book Description - Back Cover',
  authorBio: 'Author Bio',
  brokerInfo: 'Broker Information',
  authorImageFront: 'Author Image - Front Cover',
  authorImageBack: 'Author Image - Back Cover',
  brokerLogo: 'Broker Logo',
  housingLogo: 'Housing Logo',
  spineName: 'Book Title - Spine',
  spineAuthor: 'Author Name - Spine',
  brokerContact: 'Broker Contact',
  price: 'Price',
};

export const elementSections = {
  bookTitleFrontcover: sections.FRONT_COVER,
  bookTitle: sections.BACK_COVER,
  authorName: sections.FRONT_COVER,
  bookDescription: sections.BACK_COVER,
  authorBio: sections.BACK_COVER,
  brokerInfo: sections.BACK_COVER,
  authorImageFront: sections.FRONT_COVER,
  authorImageBack: sections.BACK_COVER,
  brokerLogo: sections.BACK_COVER,
  housingLogo: sections.BACK_COVER,
  spineName: sections.BACK_SPINE,
  spineAuthor: sections.BACK_SPINE,
  brokerContact: sections.BACK_SPINE,
  price: sections.BACK_SPINE,
};
