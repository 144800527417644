import React from 'react';

import S from './styles';

const Template = ({ navBar, sideBar, children }) => (
  <S.Wrapper>
    <S.NavBar>{navBar}</S.NavBar>
    <S.SideBar>{sideBar}</S.SideBar>
    <S.Content>{children}</S.Content>
  </S.Wrapper>
);

Template.defaultProps = {
  navBar: '',
  sideBar: '',
  children: '',
};

export default Template;
