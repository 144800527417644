/**
 * Colors
 */

export const PrimaryColor = '#980F20';
export const SecondaryColor = '#B2293B';
export const PrimaryColorLight = '#860E1D';
export const PrimaryColorHover = '#B21226';
export const HeaderButton = '#CA3232';

/* ************************** */

export const White = '#ffffff';

export const WhiteHEX = '255, 255, 255';
export const Black = '#000000';

/* ************************** */

export const GreyHover = '#F0F0F0';
export const GreyDropDown = '#A7A7A7';
export const GreyDropDownArrow = '#888888';
// export const GreySelectDd = "#555555";
export const GreySelectDd = '#2E3F50';
export const GreyDivider = '#E6E6E6';
export const GreyBookBorder = '#E4E4E4';
export const GreyMenuItemHover = '#f6f6f6';
export const GreyFooter = '#e9f0fd';

/* ************************** */

export const GreyFormCheckbox = '#999999';
export const GreyFormBorder = '#CCCCCC';
export const RedFormText = '#DF4545';
export const SeparatorBorder = '#E1E6F7';
export const CreditsText = '#707070';
export const PhoneLink = '#048FCD';

/* ************************** */

export const Body = '#2E3F50';
export const BodyMain = '#f7f8fc';

/* ************************** */

// export const ErrorWarning = "#FF0606";
export const ErrorWarningBorder = '#D40000';
export const ErrorWarning = '#F27575';

/* ************************** */

export const SuccessWarning = '#70C752';
export const SuccessWarningBorder = '#62AE48';

/* ************************** */

export const StepOne = '#FFAB40';
export const StepTwo = '#80D8FF';
export const StepThree = '#69F0AE';
export const StepFour = '#B2DFDB';

/* ************************** */

// export const WelcomeTitle = "#515E63";
export const WelcomeTitle = '#2E3F50';
/* ************************** */

export const Loadergrey = '#d8d8d8';

/* ************************** */

export const TitleBg = '#E6EFF7';
export const TitleBorder = '#CED4F1';
export const ImgBorder = '#6FADEB';
export const HighlightBg = '#e8f2f7';
export const IconBg = '#e7e7e7';

/* ************************** */

export const ActiveBookSectionsBg = '#f5f9fd';
export const DisabledBookSectionsBg = '#e6e8f1';
export const MenuDotsColor = '#9f9ab3';
export const MenuDotsColorBg = '#d8dcf3';
export const MenuListBg = '#ecefff';

/* ************************** */

/* *********** NEW COLORS *************** */

export const AuthLink = '#0090AF';
export const InputBorder = '#DFDFDF';

export const ErrorBg = '#fdedee';
export const ErrorBdr = '#f8a9ad';
export const FormMessageColor = '#3a3a3a';
export const BoxToolbar = '#29969f';
export const InputBrownBorder = '#d9d7d8';

/* *********** NavBar COLORS ************* */

export const ActiveMenuItemBgColor = '#a71b2c';
export const NavBarSeparatorColor = 'rgba(255, 255, 255, 0.3)';
export const UserMenuText = '#2e3f50';
export const UserMenuIcon = '#CCCCCC';
export const UserMenuEmailText = '#999999';

/* *********** BookCatalog COLORS ********** */
export const BookCatalogHeaderBg = '#569be6';
export const Grey3 = '#cecdcd';

/* *********** ViewPage COLORS ********** */
export const ViewBlueActive = '#0077ac';
export const ViewBlueButton = '#098ecd';
export const ViewBlueDisabledButton = '#9FC6DB';
export const ViewBorderColor = '#c7c7c7';
export const ViewFooterBackground = '#e6eaf0';
export const ViewFooterTitle = '#72d4ff';
export const TooltipTextColor = '#4D4D4D';
export const ViewFooterTitleBG = '#1c1c1c';

/* *********** New Palete ************* */
export const grey100 = '#d1d1d1';

/* ************* Help Page **************** */
export const InputText = '#727272';
export const DefaultBlue = '#048fcd';
export const ActiveBlue = '#0077AC';
export const DisabledButton = '#9FC6DB';
export const Placeholder = '#c9c9c9';
export const GrayBorder = '#ececec';

/*  ************ Notification *************** */
export const NotificationSuccessBG = '#cfffce';
export const NotificationSuccessBorder = '#059100';
export const NotificationTextColor = '#707070';
export const NotificationErrorBG = '#FFE5E8';
export const NotificationErrorBorder = '#980F20';
export const NotificationWarningBG = '#FFFFCE';
export const NotificationWarningBorder = '#D9D900';
