/**
 * Global Styles Theme
 */
import { transition } from 'web/theme/function';
import { createGlobalStyle } from 'styled-components';
import * as color from 'web/theme/color';
import * as font from 'web/theme/font';

const GlobalStyle = createGlobalStyle`

  html {
    font-size: 62.5%;
  }

  .ant-dropdown {
    width: 280px;
    top: 80px !important;
    z-index: 100000 !important;
    position: fixed !important;
    margin-left: -5px !important;

    ul {
      padding: 0;

      &::after {
        pointer-events: none;
        position: absolute;
        z-index: -1;
        content: "";
        border-style: solid;
        transition-duration: .3s;
        transition-property: transform;
        left: 6px;
        top: 0;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent ${color.White} transparent;
        transform: translateY(-8px);
      }

      li {
        border-bottom: 1px solid ${color.GreyDivider};

        a {
          font-size: 14px;
          line-height: 44px;
          font-weight: 600;
          padding: 5px 15px;

          &:hover {
            background-color: ${color.GreyMenuItemHover};
          }
        }

        &:hover {
          background-color: ${color.GreyMenuItemHover};
        }
      }
    }

    .userDropdown {
      &::after {
        left: auto;
        right: 20px;
      }

      li {
        a {
          line-height: 40px;

          .ant-avatar-image {
            height: 48px;
            width: 48px;
            display: inline-block;
            vertical-align: top;
            margin: 15px 0;
          }

          .userDetails {
            display: inline-block;
            vertical-align: top;
            padding: 0 0 15px 15px;
            width: calc(100% - 50px);
            margin: 15px 0 0;
            word-wrap: break-word;
            white-space: normal !important;

            .userName {
              line-height: 20px;
              font-size: 18px;
              font-weight: 700;
            }

            .userEmail {
              padding: 5px 0 0;
              color: #999999;
              line-height: 14px;
              display: block;
              padding: 5px 0 0 0;
              font-size: 13px;
            }
          }
        }
      }

      .ant-dropdown-menu-item {
        > a {
          padding: 2px 15px;
          display: flex;
          align-items: center;

          .defaultAvatar {
            svg {
              height: 50px;
              width: 40px;
              display: inline-block;
              vertical-align: middle;

              path {
                fill: #ccc;
              }
            }
          }

          svg {
            fill: #ccc;
            height: 20px;
            width: 20px;
            display: inline-block;
            vertical-align: middle;
          }

          span.menuLink {
            display: inline-block;
            vertical-align: middle;
            font-weight: 600;
            color: #2E3F50;
            padding: 0 0 0 14px;
          }
        }
      }
    }
  }

  .toolbarDropdown {
    top: 64px!important;
    width: auto;
  }


  .ant-select-dropdown {
    .ant-select-dropdown-menu {
      max-height: 255px;

      /* TODO: needs to be applied in styled components and removed from global styles
      li {
        font-size: 16px;
        line-height: 50px;
        color: #2E3F50;
        padding: 0 20px;
        border-bottom: 1px solid #e6e6e6;
        font-weight: 700;
        font-family: ${font.NunitoFont};

        &:hover,
        &.ant-select-dropdown-menu-item-active {
          background-color: #f6f6f6;
        }
      }
      */
    }
  }

  body {
    font-family: "Nunito Sans" !important;
    overflow-y: scroll;
    background: ${color.White} !important;
    word-wrap: break-word;
    white-space: normal !important;
  }

  a {
    text-decoration: none !important;
  }

  .ant-modal-wrap {
    .ant-modal-content {
      background: none;
      box-shadow: none;
    }
  }

  .ant-btn {
    border-radius: 3px;
    font-weight: bold;

    ${transition('all .2')};
    box-shadow: none;
    padding: 0 20px;
    outline: 0 !important;

    &::after {
      display: none !important;
    }

    &:hover {
      opacity: .9;
    }

    &.formBtn {
      display: block;
      width: 100%;
      font-size: 16px;
    }

    &.height--small {
      height: 40px;
    }

    &.ant-btn-primary {
      color: ${color.White};
      background-color: ${color.PrimaryColor};
      border-color: ${color.PrimaryColor};
      text-shadow: none;
      box-shadow: none;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-weight 800;
      letter-spacing: 1px;

      &:hover {
        color: ${color.White};
        background-color: ${color.PrimaryColor};
        border-color: ${color.PrimaryColor};
      }

      &:focus {
        color: ${color.White};
        background-color: ${color.PrimaryColor};
        border-color: ${color.PrimaryColor};
      }
    }

    &.ant-btn-cancel {
      color: ${color.GreySelectDd};
      background-color: ${color.GreyFormBorder};
      border-color: ${color.GreyFormBorder};
      text-shadow: none;
      box-shadow: none;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      font-weight 800;
      letter-spacing: 1px;

      &:hover {
        color: ${color.GreySelectDd};
        background-color: ${color.GreyFormBorder};
        border-color: ${color.GreyFormBorder};
      }

      &:focus {
        color: ${color.GreySelectDd};
        background-color: ${color.GreyFormBorder};
        border-color: ${color.GreyFormBorder};
      }
    }

    &.ant-btn-bordered {
      font-size: 14px;
      border: 2px solid ${color.White};
      background: transparent;
      height: 45px;
      line-height: 41px;
      color: ${color.White};
      padding-left: 20px;
      padding-right: 20px;
    }

    &.btnLink {
      border: 0;
      color: ${color.PrimaryColor};
      background: none;
    }

    &.disabled {
      cursor: default;
      background-color: #DABEC2 !important;
      border-color: transparent !important;
    }
  }

  .ant-select-dropdown {
    font-family: ${font.NunitoFont};
  }

  .ant-popover {
    &.listMenu {
      padding-top: 0;

      .ant-popover-arrow {
        display: none;
      }

      .ant-popover-inner {
        border: 1px solid ${color.TitleBorder};
        border-radius: 3px;
        background-color: ${color.White};
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, .15);

        .ant-popover-inner-content {
          padding: 0;

           button {
             display: block;
             line-height: 28px;
             padding: 0 10px;
             border: 0;
             width: 100%;
             border-bottom: 1px solid ${color.TitleBorder};
             text-align: left;
             font-weight: 600;
             font-size: 12px;

             span {
               display: inline-block;
               vertical-align: middle;

               &.icon {
                 width: 20px;
               }
             }

             &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  .editorContainer {
    border: 2px solid ${color.GreyFormBorder};
  }

  .Toastify__toast--default {
    padding: 10px 20px 5px;
    border-radius: 5px;
  }

  .Toastify__toast-container--top-right {
    top: 5em;
  }

  .success_notification {
    border: 1px solid ${color.NotificationSuccessBorder};
    background: ${color.NotificationSuccessBG};
  }

  .error_notification {
    border: 1px solid ${color.NotificationErrorBorder};
    background: ${color.NotificationErrorBG};
  }

  .warning_notification {
    border: 1px solid ${color.NotificationWarningBorder};
    background: ${color.NotificationWarningBG};
  }

  .savedSuccessfully-toaster{
    background-color: #DFF0D8 !important;
    border: 1px solid #5CA340 !important;
    color: ${color.GreySelectDd} !important;
    min-height: 54px !important;
    padding: 5px 10px !important;
  }

  .savedFailure-toaster{
    background: ${color.ErrorBg} !important;
    border: 1px solid ${color.ErrorBdr} !important;
    color:${color.GreySelectDd} !important;
    min-height: 54px !important;
    padding: 5px 10px !important;
  }

  .Toastify__toast-container {
    z-index: 999999999999 !important;
  }

  .creditsDD {
    .ant-select-dropdown-menu {
      li {
        line-height: 35px;
        text-align: right;
        padding: 0 10px;
        font-size: 14px;
      }
    }

    &.creditsDDForm {
      .ant-select-dropdown-menu {
        li {
          text-align: left;
        }
      }
    }
  }

  .ant-modal-mask {
    z-index: 0;
  }

  .ant-modal-wrap {
    display: flex;
    align-items: center;
    z-index: 10;
    height: 100vh;
    overflow: hidden !important;
    background-color: rgba(0, 0, 0, .65) !important;

    .ant-modal {
      top: 0;

      @media (max-width: 1000px) {
        max-width: calc(100% - 16px);
      }
    }

    .ant-modal-content {
      background: none;
      box-shadow: none;
    }

    .ant-modal-close {
      display: none !important;
    }

    &.transparent-bg .ant-modal-body {
      background-color: transparent !important;
    }
  }

  .antd-img-crop-modal {
    .ant-modal-body {
      background: white;
    }

    .ant-modal-header {
      background: #980F20;
      height: 37px;
      line-height: 37px;
      padding: 0 8px;

      .ant-modal-title {
        font-family: ${font.NunitoFont};
        font-weight: 800;
        letter-spacing: 0.5px;
        font-size: 16px;
        font-weight: bold;
        line-height: 37px;
        color: ${color.White};
        margin: 0;
      }
    }
  }

  .react-pdf__Page__annotations {
    display: none !important;
  }

  .react-pdf__Page__canvas{
    margin: 0 auto;
  }

  #floatingButtons {
    .btns {
      width: calc(100% - 200px);
      left: 200px;
      position: fixed;
      z-index: 10000;
      background: #F5F9FD;
      padding: 10px;
      bottom: 0;
      text-align: center;

      button {
        margin: 0 5px;
      }
    }

    .topControls {
      border-bottom: 1px solid #CED4F1;
      height: 53px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% - 200px);
      left: calc(50% + 100px);
      position: fixed;
      z-index: 10000;
      top: 64px;
      text-align: center;
      background: ${color.TitleBg};
      transform: translateX(-50%);
      border-radius: 0;

      span {
        color: ${color.GreySelectDd};
        display: inline-block;
        vertical-align: middle;
        padding: 5px 10px;
        cursor: pointer;

        svg {
          fill: ${color.GreySelectDd}
        }
      }

      .Dropdown-root {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;

        .Dropdown-control {
          background: none;
          border: 0;
          padding: 8px 26px 8px 10px;
          cursor: pointer;

          .Dropdown-arrow {
            border-color: ${color.GreySelectDd} transparent transparent;
            display: block;
            padding: 0;
            border-width: 5px 5px 0 !important;
          }

          svg {
            fill: ${color.GreySelectDd}
          }
        }

        .Dropdown-menu {
          background: ${color.TitleBg};
          border: 0;
          width: 35px;
          border-radius: 0;

          svg {
            fill: ${color.GreySelectDd};
          }

          .Dropdown-option {
            cursor: pointer;
            padding: 4px 10px;

            &.is-selected,
            &:hover {
              background: #c0d4e9;
            }
          }
        }
      }
    }
  }

  .Errortoaster {
    background: ${color.ErrorBg} !important;
    border: 1px solid ${color.ErrorBdr} !important;
    color: ${color.GreySelectDd} !important
  }

  .Toastify__close-button{
    color: ${color.GreySelectDd} !important
  }

  .draftDropdown .editordropdown::after {
    right: 127px;
  }

  .draftDropdown .editordropdown {
    top: -10px;
    box-shadow: 0 0 10px rgba(134, 134, 134, .4);
    left: -15px

    span {
      padding: 2px 15px;
      line-height: 40px;
      display: block;
      margin: -5px -12px;
      transition: all .3s;
      font-size: 14px;
      font-weight: 600;
      touch-action: manipulation;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      color: #2E3F50;
    }

    li {
      svg {
        margin: 0px 7px 0px 4px;
      }

      &:nth-of-type(2) {
        svg {
          display: none;
        }
      }
    }
  }
`;

export default GlobalStyle;
