// @flow
import React from 'react';

import { useDraft } from 'modules/common/components/DraftContext';

import { Editor } from './components';
import S from './styles';

import type { EditorElementProps } from './types';

const EditorElement = ({ element, scale, showGuides }: EditorElementProps) => {
  const { editorState, setEditorState, setSelected } = useDraft(element);

  return (
    <S.Wrapper element={element} scale={scale} showGuides={showGuides}>
      <Editor
        element={element}
        setEditorState={setEditorState}
        editorState={editorState}
        onFocus={() => setSelected(element)}
      />
    </S.Wrapper>
  );
};

export default React.memo<EditorElementProps>(EditorElement);
