import React from 'react';
import { noop } from 'lodash-es';

import { PlainText, RichText, UserImage, MonochromaticImage, Spine, Custom } from '../../schemas';

const Element = ({ element, onChange = noop }) => {
  switch (element.id) {
    case 'bookTitleFrontcover':
    case 'bookTitle':
    case 'authorName': {
      return <PlainText element={element} onChange={onChange} />;
    }

    case 'bookDescription':
    case 'authorBio':
    case 'brokerInfo': {
      return <RichText element={element} onChange={onChange} />;
    }

    case 'authorImageFront':
    case 'authorImageBack': {
      return <UserImage element={element} onChange={onChange} />;
    }

    case 'brokerLogo':
    case 'housingLogo': {
      return <MonochromaticImage element={element} onChange={onChange} />;
    }

    case 'spineName':
    case 'spineAuthor': {
      return <Spine element={element} onChange={onChange} />;
    }

    case 'brokerContact':
    case 'price': {
      return <Custom element={element} onChange={onChange} />;
    }

    default: {
      return null;
    }
  }
};

export default Element;
