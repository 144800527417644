import styled from '@emotion/styled';
import { Menu as BaseMenu, Dropdown as BaseDropdown } from 'antd';

import * as color from 'web/theme/color';
import * as font from 'web/theme/font';

const Dropdown = styled(BaseDropdown)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Button = styled.div`
  display: flex;
  color: ${color.White};
  font-weight: 500;
  height: 36px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: ${font.MontserratFont};
  border-radius: 0;
  padding: 8px 15px;
  align-items: center;
`;

Button.Label = styled.span`
  display: flex;
  white-space: nowrap;
  align-items: center;
`;

Button.Icon = styled.div`
  display: flex;
  padding: 0 0 0 10px;
  vertical-align: middle;

  & > svg {
    display: block;
    height: 20px;
  }
`;

const Menu = styled(BaseMenu)`
  ::after {
    left: auto !important;
    right: 6px;
  }
`;
Menu.Item = styled(BaseMenu.Item)``;

const AvatarWrapper = styled.div`
  svg {
    path {
      fill: ${color.White};
    }
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
  }
`;

const UserName = styled.span`
  padding: 0 5px 0 10px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  text-transform: capitalize;
  font-family: ${font.NunitoFont};
  overflow: hidden;
  font-weight: 600;
  max-width: 150px;
  letter-spacing: 1px;
`;

const ProfileLink = styled.a`
  padding: 2px 15px;
  display: flex !important;
  align-items: center;
`;

const DropdownAvatar = styled.div`
  svg {
    path {
      fill: ${color.GreyFormBorder};
    }
    height: 50px;
    width: 40px;
    display: inline-block;
    vertical-align: middle;
  }
`;

const Details = styled.div`
  display: inline-block;
  padding: 0 0 15px 15px;
  margin: 15px 0 0;
`;

Details.Name = styled.div`
  line-height: 20px;
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

Details.Email = styled.div`
  color: ${color.UserMenuEmailText};
  line-height: 14px;
  display: block;
  font-size: 13px;
  padding: 5px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default {
  Dropdown,
  Button,
  Menu,
  AvatarWrapper,
  UserName,
  ProfileLink,
  DropdownAvatar,
  Details,
};
