import React from 'react';
import { Popover } from 'antd';

import { Item } from './components';

import S from './styles';

const Options = ({ children, placement, title, icon, label, trigger }) => {
  const hasLabel = Boolean(label);
  const popoverTitle = title && <S.Title>{title}</S.Title>;
  const triggerIcon = icon && <S.Button.Icon hasSpacing={hasLabel}>{icon}</S.Button.Icon>;

  return (
    <Popover placement={placement} content={children} title={popoverTitle} trigger={trigger}>
      <S.Button>
        {triggerIcon}
        {label}
      </S.Button>
    </Popover>
  );
};

Options.defaultProps = {
  trigger: 'click',
  placement: 'top',
};

Options.Item = Item;

export default Options;
