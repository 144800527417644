import React from 'react';

import { ZoomOutIcon, ZoomInIcon } from 'web/components/SvgIcon';
import S from './styles';

const Controls = ({ checked = false, onChange, onZoomIn, onZoomOut, text, zoomPercentage }) => {
  const hasZoomControls = onZoomIn || onZoomOut || zoomPercentage;

  return (
    <S.Wrapper>
      {hasZoomControls && (
        <S.Wrapper.Inner>
          {onZoomOut && (
            <S.ZoomIcon onClick={onZoomOut}>
              <ZoomOutIcon />
            </S.ZoomIcon>
          )}

          {zoomPercentage && <S.ZoomPercentage>{zoomPercentage}%</S.ZoomPercentage>}

          {onZoomIn && (
            <S.ZoomIcon onClick={onZoomIn}>
              <ZoomInIcon />
            </S.ZoomIcon>
          )}
        </S.Wrapper.Inner>
      )}

      <S.Wrapper.Inner>
        <S.Checkbox onChange={onChange} checked={checked}>
          {text}
        </S.Checkbox>
      </S.Wrapper.Inner>
    </S.Wrapper>
  );
};

export default Controls;
