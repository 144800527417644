import React from 'react';
import { Select as BaseSelect } from 'antd';
import { map, noop } from 'lodash-es';

const Select = ({ placeholder, value, values, disabled, onChange = noop }) => (
  <BaseSelect placeholder={placeholder} disabled={disabled} value={value} onChange={onChange}>
    {map(values, (item) => (
      <BaseSelect.Option key={item.value} value={item.value}>
        {item.title}
      </BaseSelect.Option>
    ))}
  </BaseSelect>
);

export default Select;
