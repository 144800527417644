// @flow
import React from "react";
import { Icon } from "antd";
import { RichUtils } from "draft-js";
import classnames from "classnames";
import { map, noop } from "lodash-es";

import type { StyleOption } from 'modules/coverEditor/components/ContentEditor/types';
import { options } from 'modules/coverEditor/components/ContentEditor/utils';

import type { InlineProps } from "./types";

const Inline = ({ editor, onChange = noop }: InlineProps) => {
  const { inlineStyles } = options;

  const handleStyle = (style) => (e) => {
    e.preventDefault();
    const state = RichUtils.toggleInlineStyle(editor, style.toUpperCase());
    onChange(state);
  };

  return map(inlineStyles, ({ icon, type, style }: StyleOption) => {
    const active = editor.getCurrentInlineStyle().has(style);

    return (
      <span
        key={type}
        className={classnames("normal", { active })}
        onClick={handleStyle(style)}
      >
        <Icon type={icon} />
      </span>
    );
  });
};

export default Inline;
