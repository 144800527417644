// @flow
import React from 'react';
import { Form, Input, Tabs } from 'antd';

import { FilePicker } from './components';

import type { ModalContentProps } from './types';
import S from './styles';

const mimeTypesElements = ['application/json', 'text/plain'];
const mimeTypesTemplate = ['image/svg+xml', 'text/plain', 'text/html', 'text/x-handlebars'];
const autoSize = { minRows: 8, maxRows: 8 };

const ModalContent = ({
  elements,
  template,
  onChangeElements,
  onChangeTemplate,
}: ModalContentProps) => {
  const handleChangeElements = (event) => {
    onChangeElements(event.target.value);
  };
  const handleChangeTemplate = (event) => {
    onChangeTemplate(event.target.value);
  };

  return (
    <S.Wrapper>
      <Form layout="vertical">
        <Form.Item label="Elements" extra="File types: .txt, .json">
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="File" key="1">
              <FilePicker mimeTypes={mimeTypesElements} onSelect={onChangeElements} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Text" key="2">
              <Input.TextArea
                autoSize={autoSize}
                value={elements}
                onChange={handleChangeElements}
              />
            </Tabs.TabPane>
          </Tabs>
        </Form.Item>
        <Form.Item label="Template" extra="File types: .txt, .html, .hbs, .svg">
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="File" key="1">
              <FilePicker mimeTypes={mimeTypesTemplate} onSelect={onChangeTemplate} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Text" key="2">
              <Input.TextArea
                autoSize={autoSize}
                value={template}
                onChange={handleChangeTemplate}
              />
            </Tabs.TabPane>
          </Tabs>
        </Form.Item>
      </Form>
    </S.Wrapper>
  );
};

export default ModalContent;
