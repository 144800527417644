// TODO: Fix the prettier import
const prettier = require('prettier');
const parserHtml = require('prettier/parser-html');
const parserPostCss = require('prettier/parser-postcss');

const format = (value) => {
  try {
    const formatted = prettier.format(value, {
      parser: 'html',
      plugins: [parserHtml, parserPostCss],
    });
    return formatted;
  } catch (e) {
    console.log(e);
    return value;
  }
};

export default format;
