import React from 'react';
import Dropzone from 'react-dropzone';

import { AddIcon } from 'web/components/SvgIcon';

import { validate } from './utils';
import S from './styles';

const FilePicker = ({ onSelect, mimeTypes }) => {
  const handleDrop = async (files) => {
    const [file] = files;

    validate(file, mimeTypes)
      .then(onSelect)
      .catch((error) => {
        // eslint-disable-next-line no-alert
        alert(error.message);
      });
  };

  return (
    <S.DragDropWrapper>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <AddIcon />
            <p>Browse files</p>
          </div>
        )}
      </Dropzone>
    </S.DragDropWrapper>
  );
};

export default FilePicker;
