import { concat, get, filter, includes, map, pick, sortBy } from 'lodash';

import { allowedElements, defaultElements, sortedElements, validKeys } from './definitions';

const parser = (elements) => {
  try {
    return JSON.parse(elements);
  } catch {
    return [];
  }
};

const prepare = (elements) => {
  return map(elements, (element) => pick(element, validKeys));
};

const getAllowed = (elements) => {
  return filter(elements, (element) => includes(allowedElements, element.id));
};

const getMissing = (elements) => {
  const existingIds = map(elements, (element) => element.id);
  const missingIds = filter(allowedElements, (id) => !includes(existingIds, id));

  return map(missingIds, (id) => get(defaultElements, id));
};

const importer = (elements) => {
  // Parse the elements string
  const parsed = parser(elements);

  // Filter the elements to have only the allowed keys
  const prepared = prepare(parsed);

  // Filter the elements by the allowed
  const allowed = getAllowed(prepared);

  // Get the default elements by the missing ones
  const missing = getMissing(allowed);

  // Combine the allowed elements with the missing elements
  const combined = concat(allowed, missing);

  // Sort the combined elements by a prederfined sequence
  const sorted = sortBy(combined, (element) => get(sortedElements, element.id));

  return sorted;
};

export default importer;
