import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 24px;

  .ant-form {
    .ant-form-item {
      margin-bottom: 11px;
    }

    .ant-form-item-label {
      display: block;
      line-height: 30px;
      text-align: left;

      label {
        font-weight: bold;
        line-height: 24px;
        display: block;

        &::after {
          display: none;
        }
      }
    }
  }
`;

export default {
  Wrapper,
};
