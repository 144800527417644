import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import * as color from 'web/theme/color';

const Wrapper = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${color.PrimaryColor};
  padding: 0 20px;
  line-height: initial;
  display: flex;
  justify-content: space-between;

  ${({ fixed }) =>
    fixed &&
    `
    position: fixed;
    top: 0;
    z-index: 2;
  `}
`;

Wrapper.Main = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 250px;
  justify-content: flex-start;
  flex-grow: 1;

  > *,
  ul {
    margin-right: 12px;
  }

  > *:last-child {
    margin-right: 0;
  }
`;

Wrapper.Side = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  > *,
  ul {
    margin-right: 12px;
  }

  > *:last-child {
    margin-right: 0;
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-self: center;
  margin-right: 10px;

  img {
    width: 160px;
    height: 40px;
  }
`;

const Separator = styled.div`
  height: 32px;
  width: 1px;
  background: ${({ showLine }) => (showLine ? color.NavBarSeparatorColor : 'none')};
  margin: 15px 0 15px 5px;
`;

export default { Wrapper, Logo, Separator };
