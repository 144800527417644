import React from 'react';
import { InputNumber } from 'antd';
import { noop } from 'lodash-es';

const Number = ({
  min,
  max,
  value,
  precision = 0,
  formatter,
  parser,
  disabled,
  onChange = noop,
}) => (
  <InputNumber
    min={min}
    max={max}
    precision={precision}
    decimalSeparator="."
    value={value}
    disabled={disabled}
    formatter={formatter}
    parser={parser}
    onChange={onChange}
  />
);

export default Number;
