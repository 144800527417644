// Must be the first line
import './configuration/render-monitoring';
import 'react-hot-loader';

import React from 'react';
import ReactDOM from 'react-dom';

import Root from './modules/Root';

import plugins from './configuration/plugins';
import graphql from './configuration/graphql';
import store from './configuration/store';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Root store={store} graphql={graphql} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

plugins();
