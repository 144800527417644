import createStyles from 'draft-js-custom-styles';

export const customStyleMap = {
  HIGHLIGHT: {
    background: 'rgb(255,255,0)',
  },
};

export const { styles, customStyleFn, exporter } = createStyles(
  [
    'font-size',
    'color',
    'background',
    'border',
    'padding',
    'font-family',
    'text-align',
    'display',
    'line-height',
  ],
  'PREFIX',
  customStyleMap,
);

export const blockStyleFn = (block) => {
  switch (block.type) {
    case 'left-align':
      return 'align-left';
    case 'center-align':
      return 'align-center';
    case 'right-align':
      return 'align-right';
    case 'justify':
      return 'justify';
    case 'border':
      return 'border';
    case 'textIndent':
      return 'textIndent';
    case 'paddingLeft':
      return 'paddingLeft';
    case 'paddingTop':
      return 'paddingTop';
    case 'paddingBottom':
      return 'paddingBottom';

    default:
      return null;
  }
};

export const getOptions = (editor) => ({
  inlineStyles: exporter(editor),
  blockStyleFn: (block) => {
    switch (block.type) {
      case 'center-align':
        return {
          style: {
            'text-align': 'center',
          },
        };

      case 'left-align':
        return {
          style: {
            'text-align': 'left',
          },
        };

      case 'right-align':
        return {
          style: {
            'text-align': 'right',
          },
        };

      case 'justify':
        return {
          style: {
            'text-align': 'justify',
          },
        };

      default:
        return {};
    }
  },
});
