import React from 'react';
import { CoverEditor } from 'modules/coverEditor/components';

import S from './styles';

const CoverViewer = ({ showGuides, elements, template }) => (
  <S.Wrapper>
    <CoverEditor showGuides={showGuides} elements={elements} template={template} />
  </S.Wrapper>
);

export default CoverViewer;
