import React from 'react';
import { Input } from 'antd';
import { noop } from 'lodash-es';

const Text = ({ value, disabled, onChange = noop }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return <Input value={value} disabled={disabled} onChange={handleChange} />;
};

export default Text;
