// @flow
import React from 'react';

import { Item } from './components';
import S from './styles';
import type { GridProps } from './types';

const Grid = ({ children }: GridProps) => <S.Wrapper>{children}</S.Wrapper>;

Grid.Item = Item;

export default Grid;
