import React, { useEffect, useState } from 'react';
import { map } from 'lodash-es';

import { NavBar } from 'modules/nav/components';
import { CoverViewer, ExportModal, ImportModal, SideBar } from './components';
import { Template as PageTemplate } from './templates';

import { importer, validate, validation } from './utils';

// eslint-disable-next-line import/no-webpack-loader-syntax
import originalTemplate from './template';

const CoverBuilder = () => {
  const [showGuides, setShowGuides] = useState(true);
  const [openExport, setOpenExport] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [elements, setElements] = useState([]);
  const [template, setTemplate] = useState('');

  const errors = validate(template);
  validation(errors);

  useEffect(() => {
    setTemplate(originalTemplate);

    const parsedElements = importer();
    setElements(parsedElements);
  }, []);

  const handleTemplateReset = () => {
    setTemplate(originalTemplate);
  };

  const handleTemplateChange = (value) => {
    setTemplate(value);
  };

  const handleElementsChange = (value) => {
    const newElements = map(elements, (element) => (element.id === value.id ? value : element));
    setElements(newElements);
  };

  const handleExportOpen = () => setOpenExport(true);
  const handleExportClose = () => setOpenExport(false);

  const handleImportOpen = () => setOpenImport(true);
  const handleImportClose = () => setOpenImport(false);

  const handleImport = (importedElements, importedTemplate) => {
    const parsedElements = importer(importedElements);
    setElements(parsedElements);

    setTemplate(importedTemplate || originalTemplate);

    setOpenImport(false);
  };

  const sideContent = (
    <NavBar.ButtonGroup>
      <NavBar.Button onClick={handleImportOpen}>Import</NavBar.Button>
      <NavBar.Button onClick={handleExportOpen}>Export</NavBar.Button>
    </NavBar.ButtonGroup>
  );
  const navBar = <NavBar fixed sideContent={sideContent} />;

  const sideBar = (
    <SideBar
      elements={elements}
      template={template}
      onTemplateReset={handleTemplateReset}
      onTemplateChange={handleTemplateChange}
      onElementsChange={handleElementsChange}
      showGuides={showGuides}
      onToggleGuides={setShowGuides}
    />
  );

  return (
    <PageTemplate sideBar={sideBar} navBar={navBar}>
      <CoverViewer showGuides={showGuides} elements={elements} template={!errors ? template : ''} />
      <ExportModal
        isOpen={openExport}
        elements={elements}
        template={template}
        onClose={handleExportClose}
      />
      <ImportModal isOpen={openImport} onImport={handleImport} onCancel={handleImportClose} />
    </PageTemplate>
  );
};

export default CoverBuilder;
