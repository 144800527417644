import styled from 'styled-components';

const Wrapper = styled.div``;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 12px 20px;

  & > button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default {
  Wrapper,
  TopBar,
};
