import styled from '@emotion/styled';

import * as color from 'web/theme/color';

const Title = styled.span`
  align-self: center;
  padding: 0 20px;
  font-size: 16px;
  color: ${color.White};
  font-weight: 600;
  margin: 0;
  display: inline-block;
  min-width: 10px;
  flex-basis: 10px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default {
  Title,
};
