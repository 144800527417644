import React, { useEffect, useState } from 'react';
import { Slider as BaseSlider } from 'antd';
import { identity, noop } from 'lodash-es';

const Slider = ({
  min,
  max,
  value,
  disabled,
  tooltipVisible,
  tooltipPlacement,
  tipFormatter = identity,
  onChange = noop,
}) => {
  const [number, setNumber] = useState(0);

  useEffect(() => {
    setNumber(value);
  }, [min, max, value, disabled]);

  const handleChange = () => {
    onChange(number);
  };

  return (
    <BaseSlider
      min={min}
      max={max}
      value={number}
      disabled={disabled}
      onChange={setNumber}
      onAfterChange={handleChange}
      tipFormatter={tipFormatter}
      tooltipVisible={tooltipVisible}
      tooltipPlacement={tooltipPlacement}
    />
  );
};

export default Slider;
