import React from 'react';
import { Row, Col, Form } from 'antd';
import { get, noop } from 'lodash-es';

import { DraftJS, Text } from '../fields';
import S from './styles';

const DefaultValueFormatted = ({ element, disabled, onChange = noop }) => {
  const placeholder = get(element, 'placeholder', '');

  const handleChange = (field) => (value) => {
    onChange({ ...element, [field]: value });
  };

  return (
    <S.Wrapper>
      <Form layout="vertical">
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item label="Default Value">
              <DraftJS disabled={disabled} element={element} onChange={onChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item label="Placeholder">
              <Text
                disabled={disabled}
                value={placeholder}
                onChange={handleChange('placeholder')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </S.Wrapper>
  );
};

export default DefaultValueFormatted;
