import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

import * as color from 'web/theme/color';

const Span = styled.span`
  display: inline-block;
  line-height: 40px;
  transition: all 0.3s;
  font-size: 14px;
  font-weight: 600;
  touch-action: manipulation;
  outline: none;
  cursor: pointer;
  color: ${color.UserMenuText};
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    fill: ${color.UserMenuIcon};
    height: 20px;
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 14px;
  }
`;

const Link = styled(Span.withComponent(RouterLink))`
  padding: 0 15px !important;
`;

const RawLink = styled(Span.withComponent('a'))`
  padding: 0 15px !important;
`;

const Text = styled.span`
  vertical-align: middle;
  font-weight: 600;
  color: ${color.UserMenuText};
`;

const ContentWrapper = styled.div`
  align-self: flex-start;
`;

export default {
  ContentWrapper,
  Span,
  Link,
  RawLink,
  Text,
};
