import React from "react";

function getDefaultProps() {
    return {
        width: "15",
        height: "15",
        fill: "#555"
    };
}

function ZoomInIcon() {
    return (
        // <svg
        //     id="zoomin"
        //     width={getDefaultProps().width}
        //     height={getDefaultProps().height}
        //     fill={getDefaultProps().fill}
        //     xmlns="http://www.w3.org/2000/svg"
        // >
        //     <path
        //         d="M8.125 6.25H6.25v1.875H5V6.25H3.125V5H5V3.125h1.25V5h1.875v1.25zM13.2325 15l-4.616875-4.616875c-.8675.54625-1.89.866875-2.990625.866875C2.518125 11.25 0 8.731875 0 5.625S2.518125 0 5.625 0s5.625 2.518125 5.625 5.625c0 1.100625-.32125 2.12375-.866875 2.990625L15 13.2325 13.2325 15zM5.625 10C8.036875 10 10 8.0375 10 5.625S8.036875 1.25 5.625 1.25 1.25 3.2125 1.25 5.625 3.213125 10 5.625 10z"
        //         // fill="#555"
        //         fillRule="nonzero"
        //         stroke="none"
        //         strokeWidth="1"
        //     />
        // </svg>
        <svg
            id="zoomin"
            width={getDefaultProps().width}
            height={getDefaultProps().height}
            fill={getDefaultProps().fill}
            aria-hidden="true" focusable="false" 
            data-prefix="far" 
            data-icon="search-plus" 
            className="svg-inline--fa fa-search-plus fa-w-16" 
            role="img" xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 512 512"
         >
         <path
            d="M312 196v24c0 6.6-5.4 12-12 12h-68v68c0 6.6-5.4 12-12 12h-24c-6.6 0-12-5.4-12-12v-68h-68c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h68v-68c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v68h68c6.6 0 12 5.4 12 12zm196.5 289.9l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L347.5 387.1c-2.3-2.3-3.5-5.3-3.5-8.5v-13.2c-36.5 31.5-84 50.6-136 50.6C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 52-19.1 99.5-50.6 136h13.2c3.2 0 6.2 1.3 8.5 3.5l121.4 121.4c4.7 4.7 4.7 12.3 0 17zM368 208c0-88.4-71.6-160-160-160S48 119.6 48 208s71.6 160 160 160 160-71.6 160-160z">
        </path>
        </svg>
    );
}

export default ZoomInIcon;
