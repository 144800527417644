import React from 'react';
import { get, noop } from 'lodash-es';
import { Row, Col, Form } from 'antd';

import env from 'environments';
import { Select } from '../fields';
import S from './styles';

const presets = {
  brokerLogo: [
    {
      title: 'light',
      value: env.BROKER_LOGO_WHITE_URL,
    },
    {
      title: 'dark',
      value: env.BROKER_LOGO_BLACK_URL,
    },
  ],
  housingLogo: [
    {
      title: 'light',
      value: env.HOUSING_LOGO_WHITE_URL,
    },
    {
      title: 'dark',
      value: env.HOUSING_LOGO_BLACK_URL,
    },
  ],
};

const DefaultValueList = ({ disabled, element, onChange = noop }) => {
  const defaultValue = get(element, 'defaultValue');
  const elementId = get(element, 'id', '');
  const values = presets[elementId] || [];

  const handleChange = (field) => (value) => {
    onChange({ ...element, [field]: value });
  };

  return (
    <S.Wrapper>
      <Form layout="vertical">
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item label="Default Value">
              <Select
                placeholder="Default Value"
                value={defaultValue}
                values={values}
                disabled={disabled}
                onChange={handleChange('defaultValue')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </S.Wrapper>
  );
};

export default DefaultValueList;
