import styled from '@emotion/styled';

import * as color from 'web/theme/color';
import * as font from 'web/theme/font';

const Button = styled.a`
  display: flex;
  align-items: center;
  color: ${color.White};
  background: ${color.SecondaryColor};
  padding: 8px 15px;
  border: 1px solid ${color.PrimaryColorLight};
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0;
  text-transform: uppercase;
  transition: all 0.2s;
  letter-spacing: 2px;
  font-family: ${font.MontserratFont};

  &:hover {
    color: ${color.White};
    opacity: 1;
    background: #900f20;
    border: 1px solid ${color.PrimaryColorLight};
  }

  ${({ disabled }) =>
    disabled &&
    `
      color: ${color.White};
      opacity: 0.5;
    `}
`;

Button.Icon = styled.span`
  display: inline-block;
  height: 20.5px !important;
  padding-right: 6px;

  svg {
    display: block;
    width: 22px;
    height: 17px;
    fill: ${color.White};

    path {
      fill: ${color.White};
    }
  }
`;

Button.Label = styled.span`
  display: inline-block;
  color: ${color.White};
`;

export default {
  Button,
};
