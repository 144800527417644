import React from 'react';
import { Row, Col, Form, Input } from 'antd';
import { get, noop } from 'lodash-es';

import { Number, Slider } from '../fields';
import S from './styles';

const Settings = ({ element, disabled, onChange = noop }) => {
  const rotationAngle = get(element, 'rotationAngle', 0);

  const handleChange = (field) => (value) => {
    onChange({ ...element, [field]: value });
  };

  const formatter = (value) => `${value} deg`;
  const parser = (value) => value.replace(' deg', '');

  return (
    <S.Wrapper>
      <Form layout="vertical">
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item label="Rotation">
              <Input.Group>
                <Row gutter={12}>
                  <Col span={6}>
                    <Number
                      disabled={disabled}
                      min={0}
                      max={360}
                      value={rotationAngle}
                      onChange={handleChange('rotationAngle')}
                      formatter={formatter}
                      parser={parser}
                    />
                  </Col>
                  <Col span={18}>
                    <Slider
                      disabled={disabled}
                      min={0}
                      max={360}
                      value={rotationAngle}
                      onChange={handleChange('rotationAngle')}
                      tipFormatter={formatter}
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </S.Wrapper>
  );
};

export default Settings;
