import styled from 'styled-components';
// TODO: Configure aliases for relative paths
import * as colors from 'web/theme/color';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const NavBar = styled.div`
  background-color: ${colors.PrimaryColor};
  color: ${colors.White};
  line-height: 64px;
  position: relative;
  z-index: 20;
  width: 100%;
  height: 64px;
`;

const SideBar = styled.div`
  flex: 6;
  max-width: 450px;
  min-height: calc(100vh - 64px);
  background: ${colors.BodyMain};
  height: 100%;
`;

const Content = styled.div`
  min-height: calc(100vh - 64px);
  flex: 1;
`;

export default { Wrapper, NavBar, SideBar, Content };
