import React from 'react';

import { Checkbox } from '../../fields';
import S from './styles';

const ToggleGuides = ({ showGuides, onToggle }) => (
  <S.Wrapper>
    <Checkbox label="Show guides" checked={showGuides} onChange={(checked) => onToggle(checked)} />
  </S.Wrapper>
);

export default ToggleGuides;
