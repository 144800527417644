import handlebars from 'handlebars';
import { parser } from 'handlebars-error-parser';

const validate = (template) => {
  let error = false;

  try {
    handlebars.precompile(template);
  } catch (e) {
    error = parser(e, template);
  }

  return error;
};

export default validate;
