import React from 'react';

import S from './styles';

const Spacer = ({ width }) => <S.Spacer width={width} />;

Spacer.defaultProps = {
  width: '370px',
};

export default Spacer;
