// @flow
import React from 'react';

import type { ItemProps } from './types';
import S from './styles';

const Item = ({ icon, title, onClick, to, href, children, ...extra }: ItemProps) => {
  const content = (
    <S.ContentWrapper>
      {icon}
      <S.Text>{title}</S.Text>
    </S.ContentWrapper>
  );

  if (to) {
    return <S.Link to={to}>{content}</S.Link>;
  }

  if (href) {
    return (
      <S.RawLink {...extra} href={href}>
        {content}
      </S.RawLink>
    );
  }

  return (
    <S.Span onClick={onClick}>
      {content}
      {children}
    </S.Span>
  );
};

export default Item;
