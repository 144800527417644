import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import * as settings from 'modules/core/settings';

const configureStore = (reducers, rootSaga, middlewares = []) => {
  const sagaMiddleware = createSagaMiddleware();

  middlewares.unshift(sagaMiddleware);

  if (settings.enableReduxLogger()) {
    // eslint-disable-next-line global-require
    const { createLogger } = require('redux-logger');
    middlewares.push(createLogger());
  }

  const composeEnhancers = settings.isDevelopmentEnvironment()
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const initialState = {};

  const store = createStore(reducers, initialState, enhancer);

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
