import React from 'react';
import { Redirect } from 'react-router';

import { Empty } from 'modules/common/components';

const withGuard = (Component = Empty, guard = () => null) => (props) => {
  const redirect = guard(props);

  if (redirect === null) {
    return <Component {...props} />;
  }

  return <Redirect to={redirect} />;
};

export default withGuard;
