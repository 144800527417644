// @flow
import React, { PureComponent, createContext, useContext } from 'react';
import { convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { debounce, noop } from 'lodash-es';

import { getOptions } from 'modules/common/utils';

import type { Element } from 'modules/common/types';
import type {
  EditorStateStateType,
  ContextType,
  ProviderProps,
  ProviderState,
  ConsumerProps,
} from './types';

import { createContextObject, getStateFromElements } from './utils';

export const DEBOUNCE_EDITOR_CHANGE = 300;

export const Context = createContext<ContextType>({});

export class Provider extends PureComponent<ProviderProps, ProviderState> {
  static getDerivedStateFromProps(props: ProviderProps, state: ProviderState) {
    if (props.templateId !== state.templateId) {
      return {
        templateId: props.templateId,
        editorState: getStateFromElements(props.elements),
        selected: null,
      };
    }
    return null;
  }

  handleChange = debounce((editorState: EditorStateStateType) => {
    const { onChange = noop } = this.props;
    const { selected } = this.state;

    if (selected === null) {
      return;
    }

    const content = editorState.getCurrentContent();
    const options = getOptions(editorState);

    const element: Element = {
      ...selected,
      defaultValue: stateToHTML(content, options),
      rawValue: convertToRaw(content),
    };

    onChange(element);
  }, DEBOUNCE_EDITOR_CHANGE);

  constructor(props: ProviderProps, context: any) {
    super(props, context);

    this.state = {
      templateId: props.templateId,
      editorState: getStateFromElements(props.elements),
      selected: null,
    };
  }

  setEditorState = (editorState: EditorStateStateType) => {
    this.setState({
      editorState,
    });
  };

  setSelected = (selected: Element) => {
    this.setState({
      selected,
    });
  };

  render() {
    const { children } = this.props;
    const { editorState, selected } = this.state;

    const context = {
      selected,
      editorState,
      setSelected: this.setSelected,
      setEditorState: this.setEditorState,
      onChange: this.handleChange,
    };

    return <Context.Provider value={context}>{children}</Context.Provider>;
  }
}

export const Consumer = ({ element, children = noop }: ConsumerProps) => (
  <Context.Consumer>
    {(context) => {
      if (typeof children === 'function') {
        children(createContextObject(context, element));
      }
    }}
  </Context.Consumer>
);

export const useDraft = (element?: Element) => {
  const context = useContext(Context);

  return createContextObject(context, element);
};
