export default `
<svg
  id="All"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 3325 2475"
>
  <defs>
    <style>
      .cls-1 {
        fill: none;
      }

      .cls-2 {
        font-size: 265px;
      }

      .cls-2,
      .cls-22,
      .cls-26,
      .cls-33,
      .cls-34,
      .cls-5,
      .cls-9 {
        fill: #fff;
      }

      .cls-2,
      .cls-26 {
        font-family: Oswald-DemiBold, Oswald;
        font-weight: 700;
      }

      .cls-3 {
        letter-spacing: -0.02em;
      }

      .cls-4 {
        letter-spacing: 0em;
      }

      .cls-5 {
        font-size: 83px;
      }

      .cls-22,
      .cls-33,
      .cls-34,
      .cls-5,
      .cls-9 {
        font-family: Roboto-Condensed, Roboto;
      }

      .cls-6 {
        letter-spacing: -0.01em;
      }

      .cls-7 {
        letter-spacing: 0em;
      }

      .cls-8 {
        letter-spacing: 0.01em;
      }

      .cls-9 {
        font-size: 42px;
      }

      .cls-10 {
        letter-spacing: -0.01em;
      }

      .cls-11 {
        letter-spacing: 0.01em;
      }

      .cls-12 {
        letter-spacing: -0.01em;
      }

      .cls-13 {
        letter-spacing: -0.02em;
      }

      .cls-14 {
        letter-spacing: -0.01em;
      }

      .cls-15 {
        letter-spacing: -0.01em;
      }

      .cls-16 {
        letter-spacing: -0.01em;
      }

      .cls-17 {
        letter-spacing: -0.01em;
      }

      .cls-18 {
        letter-spacing: -0.01em;
      }

      .cls-19 {
        letter-spacing: -0.03em;
      }

      .cls-20 {
        letter-spacing: -0.01em;
      }

      .cls-21 {
        letter-spacing: 0.01em;
      }

      .cls-22 {
        font-size: 45px;
      }

      .cls-23 {
        letter-spacing: -0.01em;
      }

      .cls-24 {
        letter-spacing: -0.06em;
      }

      .cls-25 {
        letter-spacing: -0.04em;
      }

      .cls-26 {
        font-size: 70px;
      }

      .cls-27 {
        letter-spacing: -0.02em;
      }

      .cls-28 {
        letter-spacing: 0em;
      }

      .cls-29 {
        letter-spacing: -0.01em;
      }

      .cls-30 {
        letter-spacing: 0.01em;
      }

      .cls-31 {
        fill: #1a1a1a;
      }

      .cls-32 {
        clip-path: url(#clip-path);
      }

      .cls-33 {
        font-size: 55px;
      }

      .cls-34 {
        font-size: 40px;
      }

      .cls-35 {
        letter-spacing: -0.01em;
      }

      .cls-36 {
        clip-path: url(#clip-path-2);
      }
    </style>
    <clipPath id="clip-path" transform="translate(0 0)">
      <circle class="cls-1" cx="2476" cy="1758.27" r="357.79" />
    </clipPath>
    <clipPath id="clip-path-2" transform="translate(0 0)">
      <rect
        id="Photo"
        class="cls-1"
        x="122.12"
        y="993.11"
        width="286.81"
        height="286.81"
      />
    </clipPath>
    <image id="image" width="709" height="709" xlink:href="{{userImageUrl}}" />
  </defs>
  <title>AU_REBK_00_0001_How to Win at the Game of Real Estate</title>
  <image
    id="background"
    width="3325"
    height="2475"
    xlink:href="https://app.authorify.com/afy-api-auth/static-files/books/Brokers/AU_REBK_00_0001/AI%20Design/V2%20-%20With%20Headshot/background_image.jpg"
  />
  <rect x="1626.5" width="72" height="2475" />
  <circle class="cls-31" cx="2476" cy="1758.27" r="378.53" />
  <g class="cls-32">
    <use
      id="profile_image"
      transform="translate(2117.31 1399.58) scale(1.01 1.01)"
      xlink:href="#image"
    />
  </g>
  <image
    id="broker_logo"
    width="2660"
    height="1203"
    transform="translate(636.5 1570.5) scale(0.15 0.14)"
    xlink:href="{{brokerLogo}}"
  />
  <image
    id="fair_housing_logo"
    width="1500"
    height="1000"
    transform="translate(703.75 2068.5) scale(0.17)"
    xlink:href="{{fairHousingLogo}}"
  />
  <g id="_Clip_Group_" data-name="&lt;Clip Group&gt;">
    <g class="cls-36">
      <use
        id="profile_image-2"
        data-name="profile_image"
        transform="translate(122.12 993.11) scale(0.4)"
        xlink:href="#image"
      />
    </g>
  </g>
</svg>
`;
