// @flow
import React from "react";
import { Icon } from "antd";
import { RichUtils } from "draft-js";
import classnames from "classnames";
import { map, noop } from "lodash-es";

import type { StyleOption } from 'modules/coverEditor/components/ContentEditor/types';
import { options } from 'modules/coverEditor/components/ContentEditor/utils';

import type { ListProps } from "./types";

const List = ({ editor, onChange = noop }: ListProps) => {
  const { listStyles } = options;

  const key = editor.getSelection().getStartKey();

  const blockType = editor.getCurrentContent().getBlockForKey(key).getType();

  const handleList = (list: string) => (e) => {
    e.preventDefault();
    const lists = ["ordered-list-item", "unordered-list-item"];

    if (lists.indexOf(list) >= 0) {
      const state = RichUtils.toggleBlockType(editor, list);
      onChange(state);
    }
  };

  return map(listStyles, ({ icon, type, style }: StyleOption) => (
    <span
      key={type}
      className={classnames("normal", { active: type === blockType })}
      onClick={handleList(style)}
    >
      <Icon type={icon} />
    </span>
  ));
};

export default List;
