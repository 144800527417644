import React from 'react';
import { noop } from 'lodash-es';

import { DefaultValueInput, Font, Position, Settings } from '../controls';
import S from './styles';

const Spine = ({ element, onChange = noop }) => (
  <S.Wrapper>
    <Font element={element} onChange={onChange} />
    <DefaultValueInput element={element} onChange={onChange} />
    <Position element={element} onChange={onChange} />
    <Settings element={element} onChange={onChange} disabled />
  </S.Wrapper>
);

export default Spine;
