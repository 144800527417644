import { AuthenticatedRoute, AdminRoute, PublicRoute } from './components';

export const Authenticated = 'Authenticated';
export const Admin = 'Admin';
export const Public = 'Public';

export const getRoute = (type) => {
  switch (type) {
    case Authenticated:
      return AuthenticatedRoute;

    case Admin:
      return AdminRoute;

    case Public:
    default:
      return PublicRoute;
  }
};
