import React from 'react';
import { noop } from 'lodash-es';

import { DefaultValueList, Position } from '../controls';
import S from './styles';

const MonochromaticImage = ({ options = [], element, onChange = noop }) => (
  <S.Wrapper>
    <DefaultValueList element={element} options={options} onChange={onChange} />
    <Position element={element} onChange={onChange} />
  </S.Wrapper>
);

export default MonochromaticImage;
