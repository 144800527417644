// @flow
import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import copy from 'copy-to-clipboard';

import type { ModalContentProps } from './types';
import S from './styles';

const ModalContent = ({ elements, template }: ModalContentProps) => {
  const [stringElements, setStringElements] = useState();
  const [stringTemplate, setStringTemplate] = useState();

  useEffect(() => {
    // TODO: Minify template
    setStringTemplate(template);
    setStringElements(JSON.stringify(elements));
  }, [elements, template]);

  return (
    <S.Wrapper>
      <Form layout="vertical">
        <Form.Item label="Elements">
          <Input.Search readOnly value={stringElements} enterButton="Copy" onSearch={copy} />
        </Form.Item>
        <Form.Item label="Template">
          <Input.Search readOnly value={stringTemplate} enterButton="Copy" onSearch={copy} />
        </Form.Item>
      </Form>
    </S.Wrapper>
  );
};

export default ModalContent;
