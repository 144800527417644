import styled from 'styled-components';

import * as color from 'web/theme/color';

const Wrapper = styled.div`
  background-color: ${color.GreyFooter};
  height: calc(100vh - 64px);
  position: sticky;
  top: 64px;
`;

export default {
  Wrapper,
};
