import React from 'react';
import { get, map, noop } from 'lodash-es';

import Element from '../Element';
import ElementToggle from '../ElementToggle';
import { titles } from '../../definition';
import S from './styles';

const Elements = ({ elements, onChange = noop }) => (
  <S.Collapse>
    {map(elements, (element) => {
      const elementStatus = get(element, 'status', 'active');
      const disabled = elementStatus !== 'active';
      const elementId = get(element, 'id', '');

      const handleChange = (value, event) => {
        event.stopPropagation();
        const status = value ? 'active' : 'inActive';

        onChange({ ...element, status });
      };

      const extra = <ElementToggle disabled={disabled} element={element} onChange={handleChange} />;

      return (
        <S.Panel key={elementId} header={titles[elementId]} extra={extra}>
          <S.Panel.Content>
            <Element disabled={disabled} element={element} onChange={onChange} />
          </S.Panel.Content>
        </S.Panel>
      );
    })}
  </S.Collapse>
);

export default Elements;
