import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0 12px;
  user-select: none;
`;

export default {
  Wrapper,
};
