import { includes } from 'lodash-es';

const DEFAULT_MIME_TYPES = [
  'application/json',
  'image/svg+xml',
  'text/plain',
  'text/html',
  'text/x-handlebars',
];
const FILE_SIZE_LIMIT = 2000000;
const INVALID_FILE_SIZE_MESSAGE = 'File size must not exceed 2 MB.';
const INVALID_TYPE_MESSAGE = 'Invalid file type selected.';

export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => resolve(event.target.result));
    reader.addEventListener('error', () => reject());
    reader.readAsText(file);
  });
};

export const validate = (file, mimeTypes) => {
  const mime = mimeTypes || DEFAULT_MIME_TYPES;
  const { type, size } = file;

  if (size > FILE_SIZE_LIMIT) {
    throw new Error(INVALID_FILE_SIZE_MESSAGE);
  }

  if (!includes(mime, type)) {
    throw new Error(INVALID_TYPE_MESSAGE);
  }

  return readFile(file);
};
