// @flow
import React, { Children } from 'react';

import type { ToolbarProps } from './types';
import { Button, Controls, Link, Options, Spacer } from './components';
import S from './styles';

const Toolbar = ({ children }: ToolbarProps) => (
  <S.Wrapper>
    <S.Wrapper.List>
      {Children.map(
        children,
        (child) => child && <S.Wrapper.List.Item>{child}</S.Wrapper.List.Item>,
      )}
    </S.Wrapper.List>
  </S.Wrapper>
);

Toolbar.Button = Button;
Toolbar.Controls = Controls;
Toolbar.Link = Link;
Toolbar.Options = Options;
Toolbar.Spacer = Spacer;

export default Toolbar;
