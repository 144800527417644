import React from 'react';

import { ChevronIcon } from 'web/components/SvgIcon';
import env from 'environments';
import S from './styles';

const SitesDropdown = () => {
  const Overlay = (
    <S.Overlay>
      <S.Overlay.Item>
        <S.Overlay.Title>Training Site</S.Overlay.Title>
        <S.Overlay.Text>
          This is your one-stop shop for generating more sales with <br />
          Authorify
        </S.Overlay.Text>
        <S.Overlay.Button type="primary" target="_blank" href={env.TRAINING_URL}>
          View now
        </S.Overlay.Button>
      </S.Overlay.Item>
      <S.Overlay.Item>
        <S.Overlay.Title>Print Site</S.Overlay.Title>
        <S.Overlay.Text>
          Promote your books with customizable marketing materials and templates.
        </S.Overlay.Text>
        <S.Overlay.Button type="primary" target="_blank" href={env.PRINT_URL}>
          View now
        </S.Overlay.Button>
      </S.Overlay.Item>
      <S.Overlay.Item>
        <S.Overlay.Title>Campaigns</S.Overlay.Title>
        <S.Overlay.Text>
          We will help you list more homes <br />
          by sending multi-step listing <br />
          campaigns to your leads.
        </S.Overlay.Text>
        <S.Overlay.Button type="primary" target="_blank" href={env.CAMPAIGNS_URL}>
          Learn more
        </S.Overlay.Button>
      </S.Overlay.Item>
    </S.Overlay>
  );

  return (
    <S.Dropdown
      overlay={Overlay}
      overlayClassName="toolbarDropdown"
      placement="bottomCenter"
      trigger={['hover']}
    >
      <S.Link>
        <S.Link.Label>Sites</S.Link.Label>
        <S.Link.Icon>
          <ChevronIcon />
        </S.Link.Icon>
      </S.Link>
    </S.Dropdown>
  );
};

export default SitesDropdown;
