import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import BaseScrollbars from 'react-custom-scrollbars';

const StyledScrollbars = styled(BaseScrollbars, {
  shouldForwardProp: (prop) => prop !== 'renderInLayers',
})`
  & > div {
    scroll-behavior: smooth;

    ${({ renderInLayers }) =>
      renderInLayers &&
      css`
        will-change: transform;
      `}
  }
`;

const Scrollbars = forwardRef(({ renderInLayers, ...props }, ref) => (
  <StyledScrollbars
    ref={ref}
    autoHide
    hideTracksWhenNotNeeded
    renderInLayers={renderInLayers}
    {...props}
  />
));

export default Scrollbars;
