// @flow
import React from 'react';

import type { ProgressBarProps } from './types';
import S from './styles';

const ProgressBar = ({ scale }: ProgressBarProps) => (
  <S.Progress scale={scale}>
    <S.Bar scale={scale} />
  </S.Progress>
);

ProgressBar.defaultProps = {
  scale: 1,
};

export default ProgressBar;
