import React from 'react';
import { Button } from 'antd';
import { noop } from 'lodash-es';

import { ControlledEditor as Monaco } from '@monaco-editor/react';

import { validate, validation } from '../../utils';
import S from './styles';

const Template = ({ template, onReset = noop, onChange = noop }) => {
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  /*
  const handleFormat = () => {
    const formatted = format(template);
    onChange(formatted);
  };
  */

  const handleValidation = () => {
    const errors = validate(template);
    validation(errors, true);
  };

  return (
    <S.Wrapper>
      <S.TopBar>
        <Button onClick={handleValidation}>Validate</Button>
        {/* <Button onClick={handleFormat}>Format</Button> */}
        <Button onClick={onReset}>Reset</Button>
      </S.TopBar>
      <Monaco
        value={template}
        onChange={handleChange}
        height="80vh"
        language="handlebars"
        options={{
          scrollBeyondLastLine: false,
          wordWrapColumn: 100,
          formatOnPaste: true,
          autoClosingBrackets: true,
          autoClosingQuotes: true,
          autoIndent: true,
          codeLens: false,
          dragAndDrop: false,
          folding: true,
          mouseWheelZoom: false,
          minimap: {
            enabled: false,
          },
        }}
      />
    </S.Wrapper>
  );
};

export default Template;
