import React from 'react';

import S from './styles';

const Button = ({ onClick, disabled, children }) => (
  <S.Button onClick={onClick} disabled={disabled}>
    {children}
  </S.Button>
);

export default Button;
