const defaultConfig = {
  service: {
    crop: {
      height: 240,
      weidth: 468,
    },
    authoreImageTypeAllowed: ['jpeg', 'jpg', 'png'],
    allowedUploadImageSize: {
      height: 500,
      weidth: 500,
    },
    buyCreditsContactInfo: {
      phoneNumber: '904-695-9933',
    },
  },
};

const boolean = (value) => {
  const regex = /^(?:f(?:alse)?|no?|0+)$/i;
  return !regex.test(value) && !!value;
};

export default {
  ...defaultConfig,
  PUBLIC_URL: process.env.PUBLIC_URL,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  BASE_AUTH_URL_STATIC_FILES: process.env.REACT_APP_BASE_AUTH_URL_STATIC_FILES,
  GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL,
  REDIRECT_URL: process.env.REACT_APP_REDIRECT_URL,
  CREDITS_REDIRECT_URL: process.env.REACT_APP_CREDITS_REDIRECT_URL,
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL,
  SUPPORT_PHONE: process.env.REACT_APP_SUPPORT_PHONE,
  SELL_CREDITS_LINK_URL: process.env.REACT_APP_SELL_CREDITS_LINK_URL,
  SELL_CREDITS_LINK_LABEL: process.env.REACT_APP_SELL_CREDITS_LINK_LABEL,
  TERMS_URL: process.env.REACT_APP_TERMS_URL,
  UPDATE_BILLING_INFO: process.env.REACT_APP_UPDATE_BILLING_INFO,
  ADMIN_PREFIX: process.env.REACT_APP_ADMIN_PREFIX,
  ADMIN_ENABLED: boolean(process.env.REACT_APP_ADMIN_ENABLED),
  MASTER_ENABLED: boolean(process.env.REACT_APP_MASTER_ENABLED),
  LOGGER: boolean(process.env.REACT_APP_LOGGER),
  TRAINING_URL: process.env.REACT_APP_TRAINING_URL,
  PRINT_URL: process.env.REACT_APP_PRINT_URL,
  HUB_URL: process.env.REACT_APP_HUB_URL,
  CAMPAIGNS_URL: process.env.REACT_APP_CAMPAIGNS_URL,
  BROKER_LOGO_WHITE_URL: process.env.REACT_APP_BROKER_LOGO_WHITE_URL,
  BROKER_LOGO_BLACK_URL: process.env.REACT_APP_BROKER_LOGO_BLACK_URL,
  HOUSING_LOGO_WHITE_URL: process.env.REACT_APP_HOUSING_LOGO_WHITE_URL,
  HOUSING_LOGO_BLACK_URL: process.env.REACT_APP_HOUSING_LOGO_BLACK_URL,
};
