import { ApolloClient, HttpLink, ApolloLink, InMemoryCache } from 'apollo-boost';
import env from 'environments';

import { getItem } from 'shared/commons';

const httpLink = new HttpLink({ uri: `${env.GRAPHQL_URL}/graphql` });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = getItem('token');

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const graphql = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default graphql;
