export const sortedElements = {
  bookTitleFrontcover: 1,
  bookTitle: 2,
  spineName: 3,
  authorName: 4,
  spineAuthor: 5,
  authorImageFront: 6,
  authorImageBack: 7,
  bookDescription: 8,
  authorBio: 9,
  brokerInfo: 10,
  brokerLogo: 11,
  housingLogo: 12,
  brokerContact: 13,
  price: 14,
};

export const allowedElements = [
  'bookTitleFrontcover',
  'bookTitle',
  'spineName',
  'authorName',
  'spineAuthor',
  'authorImageFront',
  'authorImageBack',
  'bookDescription',
  'authorBio',
  'brokerInfo',
  'brokerLogo',
  'housingLogo',
  'brokerContact',
  'price',
];

export const validKeys = [
  '_id',
  'id',
  'type',
  'name',
  'defaultValue',
  'rawValue',
  'isEditable',
  'top',
  'left',
  'width',
  'height',
  'fontFamily',
  'fontSize',
  'fontWeight',
  'lineHeight',
  'letterSpacing',
  'wordSpacing',
  'textAlign',
  'color',
  'rotationAngle',
  'status',
];

export const defaultElements = {
  bookTitleFrontcover: {
    id: 'bookTitleFrontcover',
    type: 'text',
    name: 'Book Title',
    defaultValue: 'HERE SHOULD COME THE BOOK TITLE',
    rawValue: '',
    isEditable: true,
    top: 210,
    left: 1826,
    width: 1324,
    height: 1126,
    fontFamily: 'Oswald',
    fontWeight: 'normal',
    fontSize: 265,
    lineHeight: 265,
    letterSpacing: 0,
    wordSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    rotationAngle: 0,
    status: 'active',
  },
  bookTitle: {
    id: 'bookTitle',
    type: 'text',
    name: 'Book Title',
    defaultValue: 'HERE SHOULD COME THE BOOK TITLE',
    rawValue: '',
    isEditable: true,
    top: 122,
    left: 526,
    width: 612,
    height: 172,
    fontFamily: 'Oswald',
    fontWeight: 'normal',
    fontSize: 70,
    lineHeight: 70,
    letterSpacing: 0,
    wordSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    rotationAngle: 0,
    status: 'active',
  },
  authorName: {
    id: 'authorName',
    type: 'text',
    name: 'Author Name',
    defaultValue: 'AUTHOR NAME',
    rawValue: '',
    isEditable: true,
    top: 2266,
    left: 1752,
    width: 1278,
    height: 98,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 83,
    lineHeight: 83,
    letterSpacing: 0,
    wordSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    rotationAngle: 0,
    status: 'active',
  },
  bookDescription: {
    id: 'bookDescription',
    type: 'text',
    name: 'Book Description',
    defaultValue:
      '<p>Here comes the back cover description with a formatted value. Here comes the back cover description with a formatted value. Here comes the back cover description with a formatted value. Here comes the back cover description with a formatted value. </p><br /><p>Here comes the back cover description with a formatted value. Here comes the back cover description with a formatted value. Here comes the back cover description with a formatted value. </p><br /><p>Here comes the back cover description with a formatted value. Here comes the back cover description with a formatted value. Here comes the back cover description with a formatted value.</p>',
    rawValue: '',
    isEditable: true,
    top: 370,
    left: 122,
    width: 1418,
    height: 560,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 42,
    lineHeight: 42,
    letterSpacing: 0,
    wordSpacing: 0,
    textAlign: 'left',
    color: '#ffffff',
    rotationAngle: 0,
    status: 'active',
  },
  authorBio: {
    id: 'authorBio',
    type: 'text',
    name: 'Author Bio',
    defaultValue:
      '<p>Here comes the back cover description with a formatted value. Here comes the back cover description with a formatted value. Here comes the back cover description with a formatted value. </p><br /><p>Here comes the back cover description with a formatted value. Here comes the back cover description with a formatted value.</p>',
    rawValue: '',
    isEditable: true,
    top: 994,
    left: 438,
    width: 1100,
    height: 404,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 42,
    lineHeight: 42,
    letterSpacing: 0,
    wordSpacing: 0,
    textAlign: 'left',
    color: '#ffffff',
    rotationAngle: 0,
    status: 'active',
  },
  brokerInfo: {
    id: 'brokerInfo',
    type: 'text',
    name: 'Broker Information',
    defaultValue: 'HERE COMES THE BROKER INFORMATION',
    rawValue: '',
    isEditable: true,
    top: 1774,
    left: 92,
    width: 1478,
    height: 134,
    fontFamily: 'Roboto Condensed',
    fontWeight: 'normal',
    fontSize: 55,
    lineHeight: 55,
    letterSpacing: 0,
    wordSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    rotationAngle: 0,
    status: 'active',
  },
  authorImageFront: {
    id: 'authorImageFront',
    type: 'image',
    name: 'Author Image',
    defaultValue:
      'https://app.authorify.com/afy-api-auth/static-files/books/Brokers/AU_REBK_00_0001/AI%20Design/V2%20-%20With%20Headshot/profile_image.jpg',
    rawValue: '',
    isEditable: true,
    top: 1400,
    left: 2118,
    width: 716,
    height: 716,
    fontFamily: null,
    fontWeight: null,
    fontSize: null,
    lineHeight: null,
    letterSpacing: null,
    wordSpacing: null,
    textAlign: null,
    color: null,
    rotationAngle: 0,
    status: 'active',
  },
  authorImageBack: {
    id: 'authorImageBack',
    type: 'image',
    name: 'Author Image',
    defaultValue:
      'https://app.authorify.com/afy-api-auth/static-files/books/Brokers/AU_REBK_00_0001/AI%20Design/V2%20-%20With%20Headshot/profile_image.jpg',
    rawValue: '',
    isEditable: true,
    top: 992,
    left: 122,
    width: 282,
    height: 282,
    fontFamily: null,
    fontWeight: null,
    fontSize: null,
    lineHeight: null,
    letterSpacing: null,
    wordSpacing: null,
    textAlign: null,
    color: null,
    rotationAngle: 0,
    status: 'active',
  },
  brokerLogo: {
    id: 'brokerLogo',
    type: 'image',
    name: 'Broker Logo',
    defaultValue:
      'https://app.authorify.com/afy-api-auth/static-files/books/logo/brokerLogoWhite.png',
    rawValue: '',
    isEditable: true,
    top: 1570,
    left: 636,
    width: 390,
    height: 170,
    fontFamily: null,
    fontWeight: null,
    fontSize: null,
    lineHeight: null,
    letterSpacing: null,
    wordSpacing: null,
    textAlign: null,
    color: null,
    rotationAngle: 0,
    status: 'active',
  },
  housingLogo: {
    id: 'housingLogo',
    type: 'image',
    name: 'Fair Housing Logo',
    defaultValue:
      'https://app.authorify.com/afy-api-auth/static-files/books/logo/housingLogoWhite.png',
    rawValue: '',
    isEditable: false,
    top: 2068,
    left: 703,
    width: 255,
    height: 170,
    fontFamily: null,
    fontWeight: null,
    fontSize: null,
    lineHeight: null,
    letterSpacing: null,
    wordSpacing: null,
    textAlign: null,
    color: null,
    rotationAngle: 0,
    status: 'active',
  },
  spineName: {
    id: 'spineName',
    type: 'text',
    name: 'Book Title',
    defaultValue: 'HERE SHOULD COME THE BOOK TITLE',
    rawValue: '',
    isEditable: false,
    top: 112,
    left: 1634,
    width: 50,
    height: 1026,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 45,
    lineHeight: 45,
    letterSpacing: 0,
    wordSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    rotationAngle: 90,
    status: 'active',
  },
  spineAuthor: {
    id: 'spineAuthor',
    type: 'text',
    name: 'Author Name',
    defaultValue: 'AUTHOR NAME',
    rawValue: '',
    isEditable: false,
    top: 1238,
    left: 1634,
    width: 50,
    height: 1124,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 45,
    lineHeight: 45,
    letterSpacing: 0,
    wordSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    rotationAngle: 90,
    status: 'active',
  },
  brokerContact: {
    id: 'brokerContact',
    type: 'text',
    name: 'Broker Contact',
    defaultValue: '<p>email@authorify.com<br/>123-456-7890</p>',
    rawValue: '',
    isEditable: true,
    top: 1930,
    left: 92,
    width: 1480,
    height: 148,
    fontFamily: 'Roboto Condensed',
    fontWeight: 'normal',
    fontSize: 55,
    lineHeight: 55,
    letterSpacing: 0,
    wordSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    rotationAngle: 0,
    status: 'active',
  },
  price: {
    id: 'price',
    type: 'text',
    name: 'Price',
    defaultValue: '<p>U.S.A. $19.99<br>CANADA $26.39</p>',
    rawValue: '',
    isEditable: false,
    top: 2278,
    left: 92,
    width: 1480,
    height: 84,
    fontFamily: 'Roboto Condensed',
    fontWeight: 'normal',
    fontSize: 40,
    lineHeight: 40,
    letterSpacing: 0,
    wordSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    rotationAngle: 0,
    status: 'active',
  },
};
