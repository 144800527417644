// @flow
import { EditorState, convertFromRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { filter, get, noop } from 'lodash-es';

import type { EditorState as EditorStateType } from 'draft-js';
import type { Element } from 'modules/common/types';
import type { EditorStateStateType, ContextType } from './types';

// TODO: Write tests
export const getStateFromElements = (elements: Array<Element>): EditorStateStateType => {
  const editable = filter(elements, ({ isEditable }) => isEditable);

  return editable.reduce((acc, element) => {
    const rawValue = get(element, 'rawValue');
    let defaultValue = get(element, 'defaultValue');

    // for some reason, this is showing the text content as "null" without this
    if (defaultValue === null) {
      defaultValue = '';
    }

    const content = rawValue ? convertFromRaw(rawValue) : stateFromHTML(defaultValue);
    const state = EditorState.createWithContent(content);

    return {
      ...acc,
      [element.id]: state,
    };
  }, {});
};

// TODO: Write tests
export const createContextObject = (context: ContextType, element?: Element) => {
  const { selected, setSelected, editorState, setEditorState, onChange = noop } = context;
  const key = (element && element.id) || (selected && selected.id);
  const fallback = EditorState.createEmpty();

  const setEditorStateByKey = (editor: EditorStateType) => {
    if (key) {
      onChange(editor);
      setEditorState({
        ...editorState,
        [key]: editor,
      });
    }
  };

  return {
    selected,
    setSelected,
    editorState: get(editorState, key, fallback),
    setEditorState: setEditorStateByKey,
  };
};
