import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: grid;
  gap: 8px;
  padding-bottom: 12px;
  grid-auto-columns: max-content;
  grid-row: initial;
  grid-template-columns: repeat(5, 44px);
  align-items: center;
`;

export default { Wrapper };
