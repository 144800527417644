import WebFont from 'webfontloader';
import { map } from 'lodash-es';

import { getItem } from 'shared/commons';

const allowed = ['production', 'staging'];

const fonts = (families) => {
  if (WebFont) {
    const weight =
      'ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900';

    WebFont.load({
      google: {
        families: map(families, (family) => [family, weight].join(':')),
      },
    });
  }
};

const intercom = () => {
  if (!window.Intercom) {
    return;
  }
  window.Intercom('boot', {
    app_id: window.APP_ID,
  });
};

const rollbar = (payload) => {
  if (!window.Rollbar) {
    return;
  }

  window.Rollbar.configure({
    payload,
  });
};

const plugins = () => {
  if (allowed.indexOf(process.env.NODE_ENV) >= 0) {
    const person = getItem('userData', 'object');

    rollbar({ person });
    intercom();
  }

  const families = [
    'Caladea',
    'Minion Pro',
    'Oswald',
    'Roboto',
    'Roboto Condensed',
    'Frank Ruhl Libre',
    'Heebo',
    'Lato',
    'Rubik',
  ];

  fonts(families);
};

export default plugins;
