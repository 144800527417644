import styled from '@emotion/styled';

const Toolbar = styled.div`
  text-align: center;
  background: #e6eff7;
  border-bottom: 1px solid #ced4f1;
  padding: 15px 0;

  .normal {
    color: #2e3f50;
    background: no-repeat;
    border: 2px solid transparent;
    border-radius: 3px;
    font-size: 15px;
    line-height: 21px;
    font-weight: 600;
    margin: 0 3px;
    padding: 3px 5px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    display: inline-block;

    &:hover {
      background-color: #f0f5fa;
    }

    &.active {
      background-color: #fff;
      border-color: #ced4f1;
    }
  }
`;

export default {
  Toolbar,
};
