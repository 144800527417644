// @flow
import React from 'react';

import ProgressBar from '../ProgressBar';
import type { ImagePlaceholderProps } from './types';
import S from './styles';

const ImagePlaceholder = ({ width, height, scale }: ImagePlaceholderProps) => (
  <S.Wrapper width={width} height={height}>
    <ProgressBar scale={scale} />
  </S.Wrapper>
);

ImagePlaceholder.defaultProps = {
  width: '100%',
  height: '100%',
  scale: 1,
};

export default ImagePlaceholder;
