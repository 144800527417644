import styled from 'styled-components';
import { Input as BaseInput } from 'antd';
import contrast from 'contrast';

const compare = (text) => {
  switch (contrast(text)) {
    case 'dark':
      return '#fff';

    case 'light':
    default:
      return '#000';
  }
};

const ColorPicker = styled(BaseInput)`
  box-shadow: inset 0 0 0px 2px #fff;
  color: ${({ text }) => compare(text)} !important;
  background-color: ${({ color }) => color} !important;
  height: 32px !important;
`;

export default {
  ColorPicker,
};
