import React from 'react';
import { ConnectedRouter } from 'connected-react-router';

import { ScrollToTop } from 'modules/common/components';
import history from 'store/history';

import Renderer from './Renderer';
import routes from './routes';

const Router = () => (
  <ConnectedRouter history={history}>
    <ScrollToTop>
      <Renderer config={routes} />
    </ScrollToTop>
  </ConnectedRouter>
);

export default Router;
