import React, { memo } from 'react';
import { Editor as DraftEditor } from 'draft-js';

import { customStyleMap, customStyleFn, blockStyleFn } from 'modules/common/utils';

import 'draft-js/dist/Draft.css';

const Editor = ({ element, editor, onChange }) => (
  <DraftEditor
    id={element.id}
    editorState={editor}
    onChange={onChange}
    customStyleMap={customStyleMap}
    customStyleFn={customStyleFn}
    blockStyleFn={blockStyleFn}
    placeholder={element.placeholder}
  />
);

export default memo(Editor);
