// @flow
import React from "react";
import { Icon } from "antd";
import { RichUtils } from "draft-js";
import classnames from "classnames";
import { map, noop } from "lodash-es";

import type { StyleOption } from 'modules/coverEditor/components/ContentEditor/types';
import { options } from 'modules/coverEditor/components/ContentEditor/utils';

import type { BlockProps } from "./types";

const Block = ({ editor, onChange = noop }: BlockProps) => {
  const { blockStyles } = options;

  const key = editor.getSelection().getStartKey();

  const blockType = editor.getCurrentContent().getBlockForKey(key).getType();

  const handleAlignment = (textAlign) => (e) => {
    e.preventDefault();
    const state = RichUtils.toggleBlockType(editor, textAlign);
    onChange(state);
  };

  return map(blockStyles, ({ icon, type, style }: StyleOption) => (
    <span
      key={type}
      className={classnames("normal", { active: type === blockType })}
      onClick={handleAlignment(style)}
    >
      <Icon type={icon} />
    </span>
  ));
};

export default Block;
