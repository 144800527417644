// @flow
import React, { useLayoutEffect, useRef } from 'react';
import type { ElementRef } from 'react';
import { debounce, includes, map, noop } from 'lodash-es';

import type { Element } from 'modules/common/types';
import type { CoverEditorProps } from './types';

import { CoverBackground, CoverElement } from './components';
import { compileTemplate } from './utils';
import S from './styles';

const onResize = (editor: any, onScaleChange): void => {
  if (!editor) {
    return;
  }

  requestAnimationFrame(() => {
    const viewport = editor.parentElement;

    if (viewport && editor) {
      const scale = Math.min(
        viewport.clientWidth / editor.offsetWidth,
        viewport.clientHeight / editor.offsetHeight,
      );

      // eslint-disable-next-line no-param-reassign
      editor.style.transform = `scale(${scale})`;

      const { width, height } = editor.getBoundingClientRect();
      // eslint-disable-next-line no-param-reassign
      editor.style.top = `${(viewport.clientHeight - height) / 2 + 0}px`;
      // eslint-disable-next-line no-param-reassign
      editor.style.left = `${(viewport.clientWidth - width) / 2 + 0}px`;

      onScaleChange(scale);
    }
  });
};

const DEBOUNCE_RESIZE_EVENT = 300;

const CoverEditor = ({
  template,
  elements,
  scale,
  showGuides,
  loadingElements,
  onClick = noop,
  onScaleChange = noop,
}: CoverEditorProps) => {
  const ref: ElementRef<any> = useRef();

  useLayoutEffect(() => {
    // Necessary because of invalidation of props
    const debouncedResize = debounce(
      () => onResize(ref.current, onScaleChange),
      DEBOUNCE_RESIZE_EVENT,
    );

    window.addEventListener('resize', debouncedResize);
    onResize(ref.current, onScaleChange);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const compiled: string = compileTemplate(template, elements);

  return (
    <S.Wrapper>
      <S.Container ref={ref}>
        <CoverBackground template={compiled} />
        {map(elements, (element: Element) => {
          const isLoading = includes(loadingElements, element.id);

          return (
            <CoverElement
              key={element.id}
              element={element}
              scale={scale}
              showGuides={showGuides}
              onClick={onClick}
              isLoading={isLoading}
            />
          );
        })}
      </S.Container>
    </S.Wrapper>
  );
};

export default React.memo<CoverEditorProps>(CoverEditor);
