// @flow
import React from 'react';

import type { ModalProps } from './types';

import S from './styles';

const Modal = ({
  children,
  title,
  isOpen,
  width,
  footer,
  okText,
  cancelText,
  maskClosable,
  hasCloseButton,
  onConfirm,
  onCancel,
  ...props
}: ModalProps) => (
  <S.Modal
    {...props}
    title={title}
    visible={isOpen}
    width={width}
    footer={footer}
    okText={okText}
    cancelText={cancelText}
    maskClosable={maskClosable}
    hasCloseButton={hasCloseButton}
    onOk={onConfirm}
    onCancel={onCancel}
    cancelButtonProps={{ className: 'ant-btn-cancel' }}
  >
    {children}
  </S.Modal>
);

Modal.Tabs = S.Tabs;
Modal.Button = S.Button;

Modal.defaultProps = {
  width: '520px',
  hasCloseButton: true,
  maskClosable: true,
};

export default Modal;
