import styled from 'styled-components';
import { Collapse as BaseCollapse } from 'antd';

const Wrapper = styled.div``;

const Collapse = styled(BaseCollapse)`
  border-top: 1px solid #d9d9d9;

  &.ant-collapse-borderless {
    background: none !important;
  }

  .ant-collapse-header {
    color: #1890ff !important;
    border-top: 0 !important;
  }
`;

Collapse.Title = styled.p`
  font-weight: bold;
  margin-top: 30px !important;
  margin-bottom: 10px !important;
  font-size: 16px;
`;

const Panel = styled(BaseCollapse.Panel)`
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
`;

Panel.Content = styled.div``;

export default {
  Wrapper,
  Collapse,
  Panel,
};
