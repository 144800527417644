// @flow
import React from 'react';
import LogoImg from 'assets/images/logo/logo_alt.png';

import type { NavBarProps } from './types';
import { Button, ButtonGroup, Dropdown, Title, UserMenu, Menu, SitesDropdown } from './components';
import S from './styles';

const NavBar = ({ mainContent, sideContent, fixed, hasSeparator }: NavBarProps) => (
  <S.Wrapper fixed={fixed}>
    <S.Wrapper.Main>
      <S.Logo to="/">
        <img src={LogoImg} alt="Authorify Logo" />
      </S.Logo>
      <S.Separator showLine={hasSeparator} />
      {mainContent}
    </S.Wrapper.Main>
    <S.Wrapper.Side>{sideContent}</S.Wrapper.Side>
  </S.Wrapper>
);

NavBar.Button = Button;
NavBar.ButtonGroup = ButtonGroup;
NavBar.Dropdown = Dropdown;
NavBar.Title = Title;
NavBar.UserMenu = UserMenu;
NavBar.Menu = Menu;
NavBar.SitesDropdown = SitesDropdown;

export default NavBar;
