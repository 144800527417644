// @flow
import React from 'react';

import type { ButtonProps } from './types';
import S from './styles';

const Button = ({
  children,
  onClick,
  icon: Icon,
  disabled,
  'data-testid': dataTestId,
}: ButtonProps) => (
  <S.Button data-testid={dataTestId} onClick={onClick} disabled={disabled}>
    {Icon instanceof Function && (
      <S.Button.Icon>
        <Icon />
      </S.Button.Icon>
    )}
    <S.Button.Label>{children}</S.Button.Label>
  </S.Button>
);

export default Button;
