import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: fixed;
  z-index: 99;
  background: #f5f9fd;
  padding: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
`;

Wrapper.Side = styled.div`
  position: absolute;
  right: 20px;
  display: flex;
  justify-content: space-between;

  & > div {
    margin-right: 10px;
  }
`;

Wrapper.List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

Wrapper.List.Item = styled.li`
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export default {
  Wrapper,
};
