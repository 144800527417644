// @flow
import React from 'react';
import { filter, map } from 'lodash-es';

import EditorElement from 'modules/coverEditor/components/EditorElement';

import type { ContentEditorProps } from './types';
import S from './styles';

const ContentEditor = ({ layout, elements, showGuides }: ContentEditorProps) => {
  const active = filter(elements, ({ status }) => status === 'active');
  const mappedElements = map(active, (element) => {
    if (element.isEditable) {
      return <EditorElement key={element.id} showGuides={showGuides} element={element} />;
    }

    return (
      <S.Element
        key={element.id}
        showGuides={showGuides}
        element={element}
        dangerouslySetInnerHTML={{ __html: element.defaultValue }}
      />
    );
  });

  return (
    <S.Wrapper>
      <S.Panel layout={layout}>{mappedElements}</S.Panel>
    </S.Wrapper>
  );
};

ContentEditor.defaultProps = {
  layout: {},
  elements: [],
  showGuides: false,
};

export default React.memo<ContentEditorProps>(ContentEditor);
