import styled from 'styled-components';
import * as color from 'web/theme/color';

const DragDropWrapper = styled.div`
  border: 2px dotted ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.GreyMenuItemHover};
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > div {
    width: 100%;
    outline: 0;
    cursor: pointer;
  }

  svg {
    fill: #9c9a9a;
    width: 75px;
    height: 45px;
    margin: 0 auto;
    display: block;
  }

  p {
    color: ${color.GreySelectDd};
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    padding: 5px 0;
  }
`;

export default { DragDropWrapper };
