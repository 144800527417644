import qs from 'query-string';
import jwt from 'jwt-decode';

import env from 'environments';
import { getItem } from 'shared/commons';

const prefix = env.ADMIN_PREFIX;

const getData = () => {
  const token = getItem('token', 'string');
  const refreshToken = getItem('refresh_token', 'string');
  const userData = getItem('userData', 'object');
  const admin = getItem('admin', 'boolean');

  return { token, refreshToken, userData, admin };
};

const validateToken = (token) => {
  try {
    const decoded = jwt(token);
    const now = Date.now() / 1000;

    return decoded.exp > now;
  } catch {
    return false;
  }
};

const buildRedirect = (pathname, location) => {
  const redirect = location.pathname + location.search;
  const search = qs.stringify({ redirect });
  return { pathname, search };
};

export const authGuard = ({ location }) => {
  const { token, userData, refreshToken, admin } = getData();

  const isLogged = !!token && !!userData && !!refreshToken && !admin;
  const isValid = validateToken(token) && validateToken(refreshToken);

  if (isLogged && isValid) {
    return null;
  }

  return buildRedirect('/auth/login', location);
};

export const adminGuard = ({ location }) => {
  const { token, userData, admin } = getData();

  const isLogged = !!token && !!userData && !!admin;
  const isValid = validateToken(token);

  if (isLogged && isValid) {
    return null;
  }

  return buildRedirect(`/${prefix}/login`, location);
};
