import React from 'react';

function getDefaultProps() {
  return {
    width: '24',
    height: '40',
    fill: '#FFF',
  };
}

function AddIcon({ width, height }) {
  return (
    // <svg
    //   id="add"
    //   width={getDefaultProps().width}
    //   height={getDefaultProps().height}
    //   fill={getDefaultProps().fill}
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 100 100"
    // >
    //   <path
    //     d="M50 8.3333333C72.975 8.3333333 91.6666667 27.025 91.6666667 50S72.975 91.6666667 50 91.6666667 8.3333333 72.975 8.3333333 50 27.025 8.3333333 50 8.3333333zM50 0C22.3875 0 0 22.3875 0 50s22.3875 50 50 50 50-22.3875 50-50S77.6125 0 50 0zm24.6666667 54.6666667h-20v20h-9.3333334v-20h-20v-9.3333334h20v-20h9.3333334v20h20v9.3333334z"
    //     fillRule="nonzero"
    //     stroke="none"
    //     strokeWidth="1"
    //   />
    // </svg>
    <svg
      id="add"
      style={{
        width,
        height,
      }}
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="plus-circle"
      className="svg-inline--fa fa-plus-circle fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M384 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm120 16c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z"></path>
    </svg>
  );
}

AddIcon.defaultProps = {
  width: getDefaultProps().width,
  height: getDefaultProps().height,
};

export default AddIcon;
