// @flow
import React, { Children } from 'react';
import { Menu } from 'antd';
import { get } from 'lodash-es';

import { ChevronIcon } from 'web/components/SvgIcon';

import { Grid } from './components';
import type { DropdownProps } from './types';
import S from './styles';

const Dropdown = ({ children, title }: DropdownProps) => {
  const Overlay = (
    <Menu className="userDropdown editordropdown">
      {Children.map(children, (child) => {
        const key = get(child, ['props', 'title']);
        return <Menu.Item key={key}>{child}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <S.Dropdown overlay={Overlay} trigger={['click']} overlayClassName="draftDropdown">
      <S.Button>
        <S.Button.Label>{title}</S.Button.Label>
        <S.Button.Icon>
          <ChevronIcon />
        </S.Button.Icon>
      </S.Button>
    </S.Dropdown>
  );
};

Dropdown.Grid = Grid;

export default Dropdown;
