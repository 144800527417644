// @flow
import React from 'react';
import { noop } from 'lodash-es';

import { ImagePlaceholder } from 'modules/common/components';

import type { CoverElementProps } from './types';
import S from './styles';

const CoverElement = ({
  showGuides,
  element,
  scale,
  isLoading,
  onClick = noop,
}: CoverElementProps) => {
  const {
    id,
    _id,
    type,
    name,
    color,
    fontSize,
    defaultValue,
    isEditable: allowEdit,
    status,
  } = element;
  const innerHTML = { __html: defaultValue };
  const isEditable = allowEdit || id === 'brokerContact';

  const handleClick = (e) => {
    e.preventDefault();

    if (isEditable) {
      onClick(_id, id, type);
    }
  };

  const displayGuides = isEditable && showGuides;
  const title = isEditable ? name : undefined;

  if (status !== 'active') {
    return null;
  }

  switch (type) {
    case 'image': {
      const handleImageClick = !isLoading ? handleClick : noop;

      return (
        <S.Image
          title={title}
          element={element}
          isLoading={isLoading}
          isEditable={isEditable}
          showGuides={displayGuides}
          onClick={handleImageClick}
        >
          {isLoading && <ImagePlaceholder scale={scale} />}
        </S.Image>
      );
    }

    case 'text': {
      // TODO: Remove when the API has fixed this part
      // Temporary fix for elements that were supposed to be hidden
      if (fontSize === null || color === 'transparent') {
        return null;
      }

      return (
        <S.Text
          title={title}
          element={element}
          isEditable={isEditable}
          showGuides={displayGuides}
          onClick={handleClick}
          dangerouslySetInnerHTML={innerHTML}
        />
      );
    }

    default:
      return null;
  }
};

export default React.memo<CoverElementProps>(CoverElement);
