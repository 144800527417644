import React from 'react';

import S from './styles';

const Link = ({ to, disabled, children }) => (
  <S.Link className="ant-btn ant-btn-primary" to={to} disabled={disabled}>
    {children}
  </S.Link>
);

export default Link;
